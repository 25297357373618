.TOrderStatusPage .OrderStatusBox {
    margin-top: 7vh;
}

.TOrderStatusPage .OrderStatusBox .title_box>img {
    max-width: 65px;
}

.TOrderStatusPage .OrderStatusBox .title_box .tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 30px;
    line-height: 1.3;
}

.TOrderStatusPage .OrderStatusBox .title_box .sub {
    font-weight: var(--Medium);
    font-size: 24px;
    color: var(--ColorTypeB);
}

.TOrderStatusPage .OrderStatusBox .title_box .desc {
    color: var(--ColorTypeC);
    font-size: 20px;
}

.TOrderStatusPage .OrderStatusBox .title_box .desc .bold {
    font-weight: 700;
}

.TOrderStatusPage .status_box {
    border-top: 1px solid #d3d3d3;
    margin-top: 3rem;
    padding-top: 3.5rem;
}

.TOrderStatusPage .unuse_box .txt1 {
    color: var(--ColorTypeC);
    font-size: 20px;
}

.TOrderStatusPage .unuse_box .txt2 {
    color: var(--ColorTypeC);
    font-size: 18px;
    margin-bottom: 0;
}


.TOrderStatusPage .sub_tit {
    font-weight: 600;
    font-size: 22px;
    color: var(--ColorTypeB);
}

.TOrderStatusPage .status_list {
    margin-top: 2rem;
}

.TOrderStatusPage .status_items {
    display: flex;
    flex-direction: column;
    gap: 2rem 0;
    position: relative;
}

.TOrderStatusPage .status_items::before {
    content: "";
    position: absolute;
    left: calc(0.75rem + 14px);
    top: 5px;
    width: 2px;
    height: calc(100% - 25px);
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver3/process-line.png) center / 100% auto;
    z-index: -5;
}

.TOrderStatusPage .status_items::after {
    content: "";
    position: absolute;
    left: calc(0.75rem + 14px);
    top: 5px;
    width: 2px;
    height: 0;
    background-color: #000000;
    z-index: -1;
}

.TOrderStatusPage .status_list .status_item {
    display: flex;
    justify-content: flex-start;
    padding: 0 0.75rem;
}

.TOrderStatusPage .status_list .status_item .status_circle {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
}

.TOrderStatusPage .status_list .status_item .status_circle::before {
    content: "";
    width: 48%;
    height: 47%;
    background-color: #B2B2B2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.TOrderStatusPage .status_list.state1 .status_item.step1 .status_circle {
    background-color: #ECECEC;
}

.TOrderStatusPage .status_list.state2 .status_item.step1 .status_circle {
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver3/process-checkbox.png) center / 100%;
}

.TOrderStatusPage .status_list.state2 .status_item.step1 .status_circle::before {
    display: none;
}

.TOrderStatusPage .status_list.state2 .status_items::after {
    height: calc(40% - 25px);
}

.TOrderStatusPage .status_list.state2 .status_item.step2 .status_circle {
    background-color: #D6F5DE;
}

.TOrderStatusPage .status_list.state2 .status_item.step2 .status_circle::before {
    background-color: #58D678;
}

.TOrderStatusPage .status_list.state3 .status_item.step1 .status_circle,
.TOrderStatusPage .status_list.state3 .status_item.step2 .status_circle {
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver3/process-checkbox.png) center / 100%;
}

.TOrderStatusPage .status_list.state3 .status_item.step1 .status_circle::before,
.TOrderStatusPage .status_list.state3 .status_item.step2 .status_circle::before {
    display: none;
}

.TOrderStatusPage .status_list.state3 .status_items::after {
    height: calc(75% - 25px);
}

.TOrderStatusPage .status_list.state3 .status_item.step3 .status_circle {
    background: #CED7FB;
}

.TOrderStatusPage .status_list.state3 .status_item.step3 .status_circle::before {
    background: #395EEC;
}

.TOrderStatusPage .status_list.state4 .status_item.step1 .status_circle,
.TOrderStatusPage .status_list.state4 .status_item.step2 .status_circle,
.TOrderStatusPage .status_list.state4 .status_item.step3 .status_circle {
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver3/process-checkbox.png) center / 100%;
}

.TOrderStatusPage .status_list.state4 .status_item.step1 .status_circle::before,
.TOrderStatusPage .status_list.state4 .status_item.step2 .status_circle::before,
.TOrderStatusPage .status_list.state4 .status_item.step3 .status_circle::before {
    display: none;
}

.TOrderStatusPage .status_list.state4 .status_items::after {
    height: calc(100% - 25px);
}

.TOrderStatusPage .status_list.state4 .status_item.step4 .status_circle {
    background-color: #FBC5D5;
}

.TOrderStatusPage .status_list.state4 .status_item.step4 .status_circle::before {
    background-color: #EC1556;
}

.TOrderStatusPage .status_list.cancel .status_item.step1 .status_circle {
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver3/process-checkbox.png) center / 100%;
}

.TOrderStatusPage .status_list.cancel .status_item.step1 .status_circle::before {
    display: none;
}

.TOrderStatusPage .status_list.cancel .status_items::after {
    height: calc(100% - 25px);
}

.TOrderStatusPage .status_list.cancel .status_item.step5 .status_circle {
    background-color: #FCD9D9;
}

.TOrderStatusPage .status_list.cancel .status_item.step5 .status_circle::before {
    background-color: #EC1515;
}


.TOrderStatusPage .status_list .status_item .status_tit {
    font-weight: var(--Medium);
    font-size: 24px;
    line-height: 30px;
    margin-left: 1.5rem;
    margin-right: 1.25rem;
    color: var(--ColorTypeB);
    width: 90px;
}

.TOrderStatusPage .status_list.state1 .status_item.step2 .status_tit,
.TOrderStatusPage .status_list.state1 .status_item.step3 .status_tit,
.TOrderStatusPage .status_list.state1 .status_item.step4 .status_tit {
    color: #b2b2b2;
}

.TOrderStatusPage .status_list.state2 .status_item.step2 .status_tit {
    color: #58D678;
}

.TOrderStatusPage .status_list.state2 .status_item.step3 .status_tit,
.TOrderStatusPage .status_list.state2 .status_item.step4 .status_tit {
    color: #B2B2B2;
}

.TOrderStatusPage .status_list.state3 .status_item.step3 .status_tit {
    color: #395EEC;
}

.TOrderStatusPage .status_list.state3 .status_item.step4 .status_tit {
    color: #B2B2B2;
}

.TOrderStatusPage .status_list.state4 .status_item.step4 .status_tit {
    color: #EC1556;
}

.TOrderStatusPage .status_list.cancel .status_item.step5 .status_tit {
    color: #EC1515;
}


.TOrderStatusPage .status_list .status_item .status_desc {
    position: absolute;
    left: calc(120px + 3.5rem);
    font-size: 17px;
    color: var(--ColorTypeC);
    display: none;
    flex: 1;
}

.TOrderStatusPage .status_list.state1 .status_item.step1 .status_desc {
    display: block
}

.TOrderStatusPage .status_list.state2 .status_item.step2 .status_desc {
    display: block
}

.TOrderStatusPage .status_list.state3 .status_item.step3 .status_desc {
    display: block
}

.TOrderStatusPage .status_list.state4 .status_item.step4 .status_desc {
    display: block
}

.TOrderStatusPage .status_list.cancel .status_item.step5 .status_desc {
    display: block
}

.TOrderStatusPage .status_list .pickup_btn {
    margin-top: 3.5rem;
    display: none;
}

.TOrderStatusPage .status_list .pickup_btn .btn-pickup {
    border: 1px solid #D3D3D3;
    border-radius: 6px;
    font-size: 22px;
    color: var(--ColorTypeB);
    height: auto;
    padding: 1rem 0.5rem;
}

.TOrderStatusPage .status_list .pickup_btn .txt {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: var(--ColorTypeC);
    text-align: center;
    margin-top: 0.75rem;
}

.TOrderStatusPage .status_list.state3 .pickup_btn {
    display: block;
}

.TOrderStatusPage .my_order {
    margin-top: 4rem;
    padding-bottom: 3rem;
}

.TOrderStatusPage .my_order .order_list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem 0;
}

.TOrderStatusPage .my_order .order_list .order_item {
    background: #F7F7F7;
    padding: 2rem;
    border-radius: 1.75rem;
}

.TOrderStatusPage .my_order .order_list .order_item .prd_name {
    font-size: 32px;
    color: var(--ColorTypeB);
    word-break: break-word;
    margin-bottom: 0.5rem;
    font-weight: 700;
}

.TOrderStatusPage .my_order .order_list .order_item .store_name {
    font-weight: var(--Regular);
    font-size: 22px;
    color: #231815;
    word-break: break-word;
}



/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TOrderStatusPage .OrderStatusBox .title_box>img {
        max-width: 48px;
    }

    .TOrderStatusPage .OrderStatusBox .title_box .tit {
        font-size: 24px;
    }

    .TOrderStatusPage .OrderStatusBox .title_box .sub {
        font-size: 18px;
    }

    .TOrderStatusPage .OrderStatusBox .title_box .desc {
        font-size: 14px;
        margin-bottom: 0.5rem;
    }

    .TOrderStatusPage .status_box {
        padding-top: 2.5rem;
        margin-top: 2rem;
    }

    .TOrderStatusPage .unuse_box .txt1 {
        font-size: 14px;
        margin-bottom: 0.5rem;
    }

    .TOrderStatusPage .unuse_box .txt2 {
        font-size: 12px;
    }

    .TOrderStatusPage .sub_tit {
        font-size: 17px;
    }

    .TOrderStatusPage .status_list {
        margin-top: 1.25rem;
    }

    .TOrderStatusPage .status_items {
        gap: 1.5rem 0;
    }

    .TOrderStatusPage .status_items::before {
        left: calc(0.5rem + 11px);
        height: calc(100% - 15px);
    }

    .TOrderStatusPage .status_items::after {
        left: calc(0.5rem + 11px);
    }

    .TOrderStatusPage .status_list.state2 .status_items::after {
        height: calc(40% - 15px);
    }

    .TOrderStatusPage .status_list.state3 .status_items::after {
        height: calc(75% - 15px);
    }

    .TOrderStatusPage .status_list.state4 .status_items::after {
        height: calc(100% - 15px);
    }

    .TOrderStatusPage .status_list.cancel .status_items::after {
        height: calc(100% - 15px);
    }

    .TOrderStatusPage .status_list .status_item {
        padding: 0 0.5rem;
    }

    .TOrderStatusPage .status_list .status_item .status_circle {
        width: 24px;
        height: 24px;
    }

    .TOrderStatusPage .status_list .status_item .status_tit {
        font-size: 18px;
        line-height: 24px;
        margin-left: 1rem;
        margin-right: 0.75rem;
        width: 68px;
    }

    .TOrderStatusPage .status_list .pickup_btn {
        margin-top: 2.5rem;
    }

    .TOrderStatusPage .status_list .pickup_btn .btn-pickup {
        font-size: 16px;
        padding: 0.75rem 0.5rem;
    }

    .TOrderStatusPage .status_list .pickup_btn .txt {
        font-size: 12px;
        margin-top: 0.25rem;
    }

    .TOrderStatusPage .status_list .status_item .status_desc {
        font-size: 12px;
        left: calc(92px + 2.25rem);
    }

    .TOrderStatusPage .my_order {
        margin-top: 3rem;
    }

    .TOrderStatusPage .my_order .order_list {
        margin-top: 0.75rem;
        gap: 1rem 0;
    }

    .TOrderStatusPage .my_order .order_list .order_item {
        padding: 1.5rem;
        border-radius: 1.3rem;
    }

    .TOrderStatusPage .my_order .order_list .order_item .prd_name {
        font-size: 22px;
        margin-bottom: 0.25rem;
    }

    .TOrderStatusPage .my_order .order_list .order_item .store_name {
        font-size: 15px;
    }



}


@media screen and (max-width: 320px) {

    .TOrderStatusPage .OrderStatusBox .title_box .tit {
        font-size: 22px;
        margin-bottom: 0.5rem;
    }

    .TOrderStatusPage .status_list .status_item {
        padding: 0;
    }

    .TOrderStatusPage .status_items::before {
        left: 9px;
        height: calc(100% - 10px);
    }

    .TOrderStatusPage .status_items::after {
        left: 9px;
    }

    .TOrderStatusPage .status_list.state2 .status_items::after {
        height: calc(40% - 10px);
    }

    .TOrderStatusPage .status_list.state3 .status_items::after {
        height: calc(75% - 10px);
    }

    .TOrderStatusPage .status_list.state4 .status_items::after {
        height: calc(100% - 10px);
    }

    .TOrderStatusPage .status_list.cancel .status_items::after {
        height: calc(100% - 10px);
    }

    .TOrderStatusPage .status_list .status_item .status_circle {
        width: 20px;
        height: 20px;
    }

    .TOrderStatusPage .status_list .status_item .status_tit {
        font-size: 17px;
        line-height: 20px;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        width: 64px;
    }

    .TOrderStatusPage .status_list .status_item .status_desc {
        font-size: 10px;
        left: calc(84px + 1rem);
    }

    .TOrderStatusPage .status_list .pickup_btn .txt {
        font-size: 11px;
    }

    .TOrderStatusPage .my_order .order_list .order_item {
        padding: 1rem;
    }

    .TOrderStatusPage .my_order .order_list .order_item .prd_name {
        font-size: 20px;
    }

    .TOrderStatusPage .my_order .order_list .order_item .store_name {
        font-size: 13px;
    }


}