.TUseQRPage {
  position: relative;
}

.TUseQRPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TUseQRPage .QRBox-inner {
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 18rem;
  box-shadow: 0px 5px 8px 3px rgb(0 0 0 / 5%);
  position: relative;
}

.TUseQRPage .QRBox .infoitem-date {
  font-size: 22px;
  color: #231815;
  margin-bottom: 2.5rem;
}

.TUseQRPage .QRBox .infobox .infoitem {
  margin-bottom: 1.25rem;
}

.TUseQRPage .QRBox .infobox .infoitem .tit {
  font-size: 20px;
  color: var(--ColorTypeA);
}

.TUseQRPage .QRBox .infobox .infoitem .desc {
  font-weight: var(--Semibold);
  font-size: 27px;
  color: var(--ColorTypeB);
  word-break: break-word;
}

.TUseQRPage .QRBox .imgbox {
  margin: 1.25rem 0;
}

.TUseQRPage .QRBox .imgbox img.qr-img {
  display: block;
  max-width: 380px;
}

.TUseQRPage .QRBox .QRInfotxt .mob {
  display: none;
}

.TUseQRPage .QRBox .QRInfotxt {
  font-size: 20px;
  color: var(--ColorTypeC);
  position: relative;
  z-index: 5;
}

.TUseQRPage .QRBox .QRBox-bg {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 104.8%;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {
  .TUseQRPage .QRBox-inner {
    padding-bottom: 20vh;
  }

  .TUseQRPage .QRBox .infoitem-date {
    font-size: 16px;
    margin-bottom: 1.5rem;
  }

  .TUseQRPage .QRBox .infobox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TUseQRPage .QRBox .infobox .infoitem .tit {
    font-size: 15px;
  }

  .TUseQRPage .QRBox .infobox .infoitem .desc {
    font-size: 20px;
  }

  .TUseQRPage .QRBox .imgbox {
    margin: 0;
  }

  .TUseQRPage .QRBox .imgbox img.qr-img {
    max-width: 245px;
  }

  .TUseQRPage .QRBox .QRInfotxt {
    font-size: 13px;
    word-break: keep-all;
  }
}

@media screen and (max-width: 320px) {
  .TUseQRPage .QRBox .imgbox img.qr-img {
    max-width: 200px;
  }

  .TUseQRPage .QRBox .QRInfotxt .mob {
    display: block;
  }
}

/* 23-02-24 */

.TUseQRPage.unuse .QRBox-inner {
  padding-bottom: 13rem;
}

.TUseQRPage.unuse .QRBox .QRInfotxt2 {
  font-size: 17px;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  position: relative;
  padding: 2.5rem 2rem;
  margin: 0 auto 2rem;
  word-break: keep-all;
  background: #f7f7f7f7;
  width: calc(100% - 3rem);
  border-radius: 1.5rem;
}

.TUseQRPage.unuse .QRBox .QRInfotxt2 > img {
  width: 35px;
  display: block;
  margin: 0 auto 1rem;
}

.TUseQRPage.unuse .QRBox .QRInfotxt2 .call {
  font-size: 15px;
  color: var(--ColorTypeC);
  font-weight: var(--Regular);
  margin-top: 1.5rem;
}

.TUseQRPage.unuse .QRBox .QRInfotxt2 .call .mob {
  display: none;
}

.TUseQRPage.unuse .QRBox .imgbox.unuse {
  margin: 2rem 0 1.5rem;
  position: relative;
}

.TUseQRPage.unuse .QRBox .imgbox.unuse img.qr-img {
  opacity: 0.3;
}

.TUseQRPage.unuse .QRBox .imgbox .unuse-txt {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 4px solid #ff4033;
  color: #ff4033;
  font-size: 25px;
  line-height: 1;
  font-weight: var(--Bold);
}

.TUseQRPage.unuse .QRBox .imgbox .unuse-txt::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #ff4033;
  transform: scale(0.94);
}

@media screen and (max-width: 599px) {
  .TUseQRPage.unuse .QRBox-inner {
    padding-bottom: 20vh;
  }

  .TUseQRPage.unuse .QRBox .QRInfotxt2 {
    font-size: 14px;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
  }

  .TUseQRPage.unuse .QRBox .QRInfotxt2 > img {
    width: 30px;
    margin: 0 auto 0.5rem;
  }

  .TUseQRPage.unuse .QRBox .QRInfotxt2 .call {
    font-size: 13px;
    margin-top: 1rem;
  }

  .TUseQRPage.unuse .QRBox .QRInfotxt2 .call .mob {
    display: block;
  }

  .TUseQRPage.unuse .QRBox .imgbox .unuse-txt {
    width: 90px;
    height: 90px;
    font-size: 20px;
  }
}
