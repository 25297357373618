.TPaymentPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TPaymentPage .TicketBox .PageTit2 {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 28px;
}

.TPaymentPage .TicketBox {
  border-bottom: 10px solid var(--ColorTypeE);
}

.TPaymentPage .TicketBox.Check .row {
  gap: 0 30px;
}

.TPaymentPage .TicketBox.Check .row .prd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 25px;
  margin-bottom: 0.3rem;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-sub {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 17px;
  line-height: 1.3;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-price {
  font-weight: var(--Bold);
  color: var(--ColorTypeB);
  font-size: 24px;
}

.TPaymentPage .TicketBox.Info .PageTit2 img.rice {
  width: 22px;
  margin-bottom: 5px;
}

.TPaymentPage .TicketBox.Info .Total .tit {
  font-weight: var(--Medium);
  font-size: 20px;
  color: var(--ColorTypeB);
}

.TPaymentPage .TicketBox.Info .Total .price {
  font-weight: var(--Medium);
  font-size: 17px;
  color: var(--ColorTypeB);
}

.TPaymentPage .TicketBox.Info .Total .price span {
  font-weight: var(--Semibold);
  font-size: 24px;
}

.TPaymentPage .form-radiobox .RadioLabel {
  width: 100%;
  height: 55px;
  padding: 0.75rem;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeD);
  font-weight: var(--Regular);
  font-size: 17px;
  color: var(--ColorTypeB);
  transition: all 0.35s ease;
}

.TPaymentPage .form-check-input.CheckInput3 {
  transition: all 0.35s ease;
}

.TPaymentPage .form-check-input.CheckInput3:checked[type="radio"] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check_color.png);
  background-color: #fff;
  border-color: transparent;
}

.TPaymentPage
  .form-check-input.CheckInput3:checked[type="radio"]
  + .RadioLabel {
  border-color: var(--ColorTypeA);
}

.TPaymentPage .accordion.ver3 {
  --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transform2: rotate(180deg);
}

.TPaymentPage .accordion-button {
  font-size: 17px;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {
  .TPaymentPage .TicketBox .PageTit2 {
    font-size: 20px;
  }

  .TPaymentPage .TicketBox.Check .row {
    gap: 0 15px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-tit {
    font-size: 18px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-sub {
    font-size: 13px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-price {
    font-size: 18px;
  }

  .TPaymentPage .form-control.InputType {
    height: 50px;
  }

  .TPaymentPage .form-checkbox {
    height: 50px;
  }

  .TPaymentPage .form-radiobox .RadioLabel {
    font-size: 14px;
  }

  .TPaymentPage .TicketBox.Info .Total .tit {
    font-size: 14px;
  }

  .TPaymentPage .TicketBox.Info .Total .price {
    font-size: 13px;
  }

  .TPaymentPage .TicketBox.Info .Total .price span {
    font-size: 16px;
  }

  .TPaymentPage .accordion-button {
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) {
}

/* 23-01-09 수정 */

.TPaymentPage .TicketBox {
  border-color: #f8f8f8;
  position: relative;
}

.TPaymentPage .TicketBox::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ededed;
}

.TPaymentPage .TicketBox.border-0::after {
  display: none;
}

.TPaymentPage .TicketBox .PageTit2 {
  font-weight: var(--Semibold);
}

.TPaymentPage .TicketBox .PageTit2 img {
  margin-left: 0.75rem;
  width: 28px;
}

.TPaymentPage .TicketBox.Check .row .prd-info {
  font-size: 22px;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-price {
  font-size: 22px;
  font-weight: var(--Medium);
  margin-bottom: 0;
  text-align: right;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-price span {
  font-weight: var(--Bold);
  font-size: 24px;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Info .Total .price {
  word-break: break-word;
  max-width: 60%;
}

.TPaymentPage .TicketBox.Info .Total .price span {
  word-break: break-word;
}

.TPaymentPage .form-check-input.CheckInput3 {
  width: 25px;
  height: 25px;
}

.TPaymentPage .form-radiobox .RadioLabel {
  height: 50px;
  padding: 0.5rem 0.75rem;
}

.TPaymentPage .accordion.ver3 {
  margin-top: 4.5rem;
}

.TPaymentPage .accordion.ver3 .accordion-button::after {
  background-position-y: center;
}

.TPaymentPage .accordion-button {
  word-break: keep-all;
  background-color: #fff;
}

.TPaymentPage .accordion-collapse {
  font-size: 17px;
}

.demo .TPaymentPage .Payment-Confirm .btn {
  height: 70px;
  font-size: 22px;
}

@media screen and (max-width: 599px) {
  .demo .TPaymentPage .TicketBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TPaymentPage .TicketBox .PageTit2 img {
    margin-left: 0.5rem;
    width: 17px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-info {
    font-size: 16px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-price {
    font-size: 13px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-price span {
    font-size: 16px;
  }

  .TPaymentPage .TicketBox.Info .Total .tit {
    font-size: 16px;
  }

  .TPaymentPage .accordion-collapse {
    font-size: 13px;
  }

  .demo .TPaymentPage .Payment-Confirm .btn {
    height: 55px;
    font-size: 16px;
  }
}

/* 23-02-06 수정 */

.TPaymentPage .Payment-terms {
  margin-top: 4.5rem;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  font-size: 17px;
  position: relative;
  padding: 0 0.75rem;
}

.TPaymentPage .Payment-terms .termsBtn {
  font-weight: var(--Regular);
  font-size: 16px;
  color: var(--ColorTypeD);
  border-bottom: 1px solid var(--ColorTypeD);
  position: absolute;
  top: 0;
  right: 0.75rem;
}

@media screen and (max-width: 599px) {
  .TPaymentPage .Payment-terms {
    font-size: 13px;
    padding-right: 3rem;
  }

  .TPaymentPage .Payment-terms .termsBtn {
    font-size: 13px;
  }
}

.TPaymentPage .TicketBox .PageTit2 {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 28px;
}

.TPaymentPage .TicketBox {
  border-bottom: 10px solid var(--ColorTypeE);
}

.TPaymentPage .TicketBox.Check .row {
  gap: 0 30px;
}

.TPaymentPage .TicketBox.Check .row .prd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 25px;
  margin-bottom: 0.3rem;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-sub {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 17px;
  line-height: 1.3;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-price {
  font-weight: var(--Bold);
  color: var(--ColorTypeB);
  font-size: 24px;
}

.TPaymentPage .TicketBox.Info .PageTit2 img.rice {
  width: 22px;
  margin-bottom: 5px;
}

.TPaymentPage .TicketBox.Info .Total .tit {
  font-weight: var(--Medium);
  font-size: 20px;
  color: var(--ColorTypeB);
}

.TPaymentPage .TicketBox.Info .Total .price {
  font-weight: var(--Medium);
  font-size: 17px;
  color: var(--ColorTypeB);
}

.TPaymentPage .TicketBox.Info .Total .price span {
  font-weight: var(--Semibold);
  font-size: 24px;
}

.TPaymentPage .form-radiobox .RadioLabel {
  width: 100%;
  height: 55px;
  padding: 0.75rem;
  border-radius: 3px;
  border: 1px solid var(--ColorTypeD);
  font-weight: var(--Regular);
  font-size: 17px;
  color: var(--ColorTypeB);
  transition: all 0.35s ease;
}

.TPaymentPage .form-check-input.CheckInput3 {
  transition: all 0.35s ease;
}

.TPaymentPage .form-check-input.CheckInput3:checked[type="radio"] {
  background-image: url(https://www.flexdaycdn.net/public/images/static/login/signup_check_color.png);
  background-color: #fff;
  border-color: transparent;
}

.TPaymentPage
  .form-check-input.CheckInput3:checked[type="radio"]
  + .RadioLabel {
  border-color: var(--ColorTypeA);
}

.TPaymentPage .accordion.ver3 {
  --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transform2: rotate(180deg);
}

.TPaymentPage .accordion-button {
  font-size: 17px;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {
  .TPaymentPage .TicketBox .PageTit2 {
    font-size: 20px;
  }

  .TPaymentPage .TicketBox.Check .row {
    gap: 0 15px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-tit {
    font-size: 18px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-sub {
    font-size: 13px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-price {
    font-size: 18px;
  }

  .TPaymentPage .form-control.InputType {
    height: 50px;
  }

  .TPaymentPage .form-checkbox {
    height: 50px;
  }

  .TPaymentPage .form-radiobox .RadioLabel {
    font-size: 14px;
  }

  .TPaymentPage .TicketBox.Info .Total .tit {
    font-size: 14px;
  }

  .TPaymentPage .TicketBox.Info .Total .price {
    font-size: 13px;
  }

  .TPaymentPage .TicketBox.Info .Total .price span {
    font-size: 16px;
  }

  .TPaymentPage .accordion-button {
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) {
}

/* 23-01-09 수정 */

.TPaymentPage .TicketBox {
  border-color: #f8f8f8;
  position: relative;
}

.TPaymentPage .TicketBox::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ededed;
}

.TPaymentPage .TicketBox.border-0::after {
  display: none;
}

.TPaymentPage .TicketBox .PageTit2 {
  font-weight: var(--Semibold);
}

.TPaymentPage .TicketBox .PageTit2 img {
  margin-left: 0.75rem;
  width: 28px;
}

.TPaymentPage .TicketBox.Check .row .prd-info {
  font-size: 22px;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-price {
  font-size: 22px;
  font-weight: var(--Medium);
  margin-bottom: 0;
  text-align: right;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Check .row .prd-price span {
  font-weight: var(--Bold);
  font-size: 24px;
  word-break: break-word;
}

.TPaymentPage .TicketBox.Info .Total .price {
  word-break: break-word;
  max-width: 60%;
}

.TPaymentPage .TicketBox.Info .Total .price span {
  word-break: break-word;
}

.TPaymentPage .form-check-input.CheckInput3 {
  width: 25px;
  height: 25px;
}

.TPaymentPage .form-radiobox .RadioLabel {
  height: 50px;
  padding: 0.5rem 0.75rem;
}

.TPaymentPage .accordion.ver3 {
  margin-top: 4.5rem;
}

.TPaymentPage .accordion.ver3 .accordion-button::after {
  background-position-y: center;
}

.TPaymentPage .accordion-button {
  word-break: keep-all;
  background-color: #fff;
}

.TPaymentPage .accordion-collapse {
  font-size: 17px;
}

.demo .TPaymentPage .Payment-Confirm .btn {
  height: 70px;
  font-size: 22px;
}

@media screen and (max-width: 599px) {
  .demo .TPaymentPage .TicketBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TPaymentPage .TicketBox .PageTit2 img {
    margin-left: 0.5rem;
    width: 17px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-info {
    font-size: 16px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-price {
    font-size: 13px;
  }

  .TPaymentPage .TicketBox.Check .row .prd-price span {
    font-size: 16px;
  }

  .TPaymentPage .TicketBox.Info .Total .tit {
    font-size: 16px;
  }

  .TPaymentPage .accordion-collapse {
    font-size: 13px;
  }

  .demo .TPaymentPage .Payment-Confirm .btn {
    height: 55px;
    font-size: 16px;
  }
}

/* 23-02-06 수정 */

.TPaymentPage .Payment-terms {
  margin-top: 4.5rem;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  font-size: 17px;
  position: relative;
  padding: 0 0.75rem;
}

.TPaymentPage .Payment-terms .termsBtn {
  font-weight: var(--Regular);
  font-size: 16px;
  color: var(--ColorTypeD);
  border-bottom: 1px solid var(--ColorTypeD);
  position: absolute;
  top: 0;
  right: 0.75rem;
}

@media screen and (max-width: 599px) {
  .TPaymentPage .Payment-terms {
    font-size: 13px;
    padding-right: 3rem;
  }

  .TPaymentPage .Payment-terms .termsBtn {
    font-size: 13px;
  }
}

/* 23.03.16 */

.TPaymentPage .form-radiowrap {
  justify-content: space-between;
  gap: 0 0.75rem;
}

.TPaymentPage .form-radiobox {
  flex: 1;
}

.TPaymentPage .form-check-input.CheckInput3 {
  display: none;
}

.TPaymentPage .form-radiobox .RadioLabel {
  height: auto;
  padding: 1.25rem 0;
  text-align: center;
  border-radius: 0.25rem;
}

.TPaymentPage .form-radiobox .RadioLabel img {
  display: block;
  margin: 0 auto 0.2rem;
  max-width: 55px;
}

@media screen and (max-width: 599px) {
  .TPaymentPage .form-radiobox .RadioLabel {
    padding: 0.85rem 0;
    font-size: 13px;
    letter-spacing: -0.5px;
  }

  .TPaymentPage .form-radiobox .RadioLabel img {
    max-width: 45px;
  }
}
