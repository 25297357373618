/* add css styles here (optional) */

.ReactSwipeButton {
  float: none;
  width: 100%;
  height: 88px;
  position: relative;
  background-color: #d3d3d3;
  border: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 1rem;
}

.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    not supported by any browser */
}

.rsbContainer {
  float: left;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.rsbContainerUnlocked {
  width: 100% !important;
  cursor: default;
}

.rsbContainerUnlocked .rsbcSlider {
  left: 100% !important;
  cursor: default;
  pointer-events: none;
}

.rsbContainerUnlocked .rsbcSliderArrow {
  transition: all 0.5s;
  margin-right: -60px;
}

.rsbContainerUnlocked .rsbcSliderCircle {
  transition: all 0.5s;
  margin-right: -60px;
}

.rsbcSlider {
  float: left;
  position: absolute;
  width: 88px;
  height: 88px;
  left: 0;
  top: 0;
  z-index: 100;
  cursor: pointer;
  border-radius: 100px;
}

.rsbcSlider::before {
  content: "";
  position: absolute;
  left: calc(-500% + 88px);
  top: 0;
  width: 500%;
  height: 100%;
  background: linear-gradient(to right, #d3d3d3, transparent);
  border-radius: 100px;
  transition: all 0.2s;
}

.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 88px;
  font-family: inherit;
  letter-spacing: 0;
  font-weight: var(--Medium);
  font-size: 24px;
  color: var(--ColorTypeC);
}

.rsbcSliderIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  max-width: 14%;
  pointer-events: none;
}

.rsbcSliderArrow {
  float: left;
  position: absolute;
  transform: rotate(45deg);
  top: 50%;
  transform-origin: center;
  z-index: 10;
  border: 3px solid #000000;
  border-left: 0;
  border-bottom: 0;
  height: 18px;
  width: 18px;
  top: 50%;
  right: 32.5px;
  margin-top: -7.5px;
  border-radius: 3px;
}

.rsbcSliderCircle {
  position: absolute;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  border-radius: 100px;
  background-color: #fff !important;
}

.rsbcText {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;

  font-family: inherit;
  letter-spacing: 0;
  height: 88px;
  line-height: 88px;
  font-weight: var(--Medium);
  font-size: 24px;
  color: var(--ColorTypeC);
}

.rsbContainerUnlocked .rsbcText {
  opacity: 0;
}

@media screen and (max-width: 599px) {
  .ReactSwipeButton {
    height: 65px;
  }

  .rsbcSlider {
    width: 65px;
    height: 65px;
  }

  .rsbcSlider::before {
    left: calc(-350% + 65px);
    width: 350%;
  }

  .rsbcSliderText {
    line-height: 65px;
  }

  .rsbcSliderCircle {
    font-size: 16px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }

  .rsbcSliderText {
    font-size: 16px;
  }

  .rsbcText {
    height: 65px;
    line-height: 65px;
    font-size: 16px;
  }
}
