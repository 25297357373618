.TLoginPage .LogoImg {
  margin-top: 5vh;
  margin-bottom: 7vh;
}

.TLoginPage .LogoImg img {
  width: 100%;
  max-width: 130px;
}

.TLoginPage .InfoSearch {
  font-weight: var(--Regular);
  font-size: 18px;
  color: var(--ColorTypeC);
}

.TLoginPage .LoginBox > .row {
  margin-bottom: 12px;
  --bs-gutter-x: 0;
}

.TLoginPage .LoginBox > .row:nth-child(1) {
  margin-top: 12px;
}

.TLoginPage .InfoSearch > a {
  position: relative;
}

.TLoginPage .InfoSearch > a:hover {
  color: inherit;
}

.TLoginPage .InfoSearch > a::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 16px;
  background: var(--ColorTypeC);
  margin: 0px 0.7rem;
}

.TLoginPage .InfoSearch > a:last-child::after {
  display: none;
}

.TLoginPage .btn-primary {
  margin-bottom: 12px;
  margin-top: 20px;
}

.TLoginPage .LoginBox > .row {
  margin-bottom: 12px;
  --bs-gutter-x: 0;
}

.TLoginPage .JoinBox > .InfoTxt {
  font-weight: var(--Medium);
  font-size: 18px;
  color: var(--ColorTypeC);
  text-align: center;
  position: relative;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TLoginPage .LogoImg img {
    max-width: 100px;
  }

  .TLoginPage .InfoSearch {
    font-size: 14px;
  }

  .TLoginPage .btn-primary {
    margin-top: 10px;
  }

  .TLoginPage .JoinBox.mt-5 {
    margin-top: 2rem !important;
  }

  .TLoginPage .JoinBox > .InfoTxt {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .TLoginPage .LogoImg {
    margin-bottom: 6vh;
  }

  .TLoginPage .LogoImg img {
    max-width: 85px;
  }
}

/* 23-01-09 수정 */

.demo .TLoginPage .Inner {
  display: flex;
  flex-direction: column;
  gap: 3rem 0;
}

.TLoginPage .LogoImg img {
  max-width: 180px;
}

.TLoginPage .LoginBox .btn-light {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  border-color: #000000;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-default {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  padding: 1rem 1.5rem;
  position: relative;
}

.TLoginPage .LoginBox .btn-default > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  /* transform: translateY(-50%);
    -webkit-transform: translateY(-50%); */
  max-width: 22px;
}

.TLoginPage .LoginBox .btn-kakao {
  width: 100%;
  border-radius: 5px;
  background: #fee500;
  font-weight: var(--Medium);
  font-size: 20px;
  border-color: #fee500;
  position: relative;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-kakao > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  /* transform: translateY(-50%);
    -webkit-transform: translateY(-50%); */
}

.TLoginPage .LoginBox .btn-naver {
  width: 100%;
  border-radius: 5px;
  background: #03c75a;
  font-weight: var(--Medium);
  font-size: 20px;
  color: #fff;
  border-color: #03c75a;
  position: relative;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-naver > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  /* transform: translateY(-50%);
    -webkit-transform: translateY(-50%); */
}

.TLoginPage .LoginBox .btn-kakao:active {
  background: #fee500;
  border-color: #fee500;
}

.TLoginPage .LoginBox .btn-naver:active {
  background: #03c75a;
  border-color: #03c75a;
  color: #fff;
}

/* admin */

.TLoginPage.admin .LogoImg {
  margin-bottom: 5vh;
}

.TLoginPage.admin .LogoImg .txt {
  font-size: 20px;
  color: #999999;
  font-weight: var(--Medium);
  margin-top: 0.5rem;
}

.TLoginPage.admin .LoginBox > .row:nth-child(1) {
  margin-top: 0;
}

.TLoginPage.admin .LoginBox > .row {
  margin-bottom: 0.5rem;
}

.demo .TLoginPage.admin .form-control.InputType {
  height: 55px;
  height: auto;
  font-size: 18px;
  padding: 1rem 1.5rem;
}

.TLoginPage.admin .LoginBox .btn-primary {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  margin-top: 1rem;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TLoginPage .LogoImg img {
    max-width: 130px;
  }

  .TLoginPage .LoginBox .btn-light {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-default {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-kakao {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-naver {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  /* admin */
  .TLoginPage.admin .LogoImg .txt {
    font-size: 14px;
  }

  .demo .TLoginPage.admin .form-control.InputType {
    font-size: 14px;
    padding: 0.75rem;
  }

  .TLoginPage.admin .LoginBox .btn-primary {
    font-size: 16px;
    padding: 0.75rem 1.5rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 320px) {
  .TLoginPage .LogoImg {
    margin-bottom: 0;
  }

  .TLoginPage .LogoImg img {
    max-width: 100px;
  }

  /* admin */
  .TLoginPage.admin .LogoImg {
    margin-bottom: 0;
  }
}

.TLoginPage .LoginBox .btn-light {
  color: #999999;
  border-color: #fff;
}

.TLoginPage .LoginBox .btn-light:focus {
  box-shadow: none;
}

/* 23-02-27 이벤트 팝업 추가 */

.demoModal {
  overflow: hidden;
}

.demoModal .modal-popup {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  margin: 0;
}

.demoModal .modal-dialog.modal-sm.modal-popup {
  --bs-modal-width: 100%;
  width: 100%;
  transform: translateY(50px);
}

.demoModal.modal.show .modal-dialog.modal-sm.modal-popup {
  transform: none;
}

.demoModal .modal-popup .modal-content {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 0;
  border-radius: 2.5rem 2.5rem 0 0;
  overflow: hidden;
  background-color: transparent;
}

/* 23-03-23 수정 */

/* SBAggro */
@font-face {
  font-family: "SBAggro";
  font-weight: 300;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroLight.ttf")
      format("truetype");
  transform: rotate(0.04deg);
}

@font-face {
  font-family: "SBAggro";
  font-weight: 500;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroMedium.ttf")
      format("truetype");
  transform: rotate(0.04deg);
}

@font-face {
  font-family: "SBAggro";
  font-weight: 700;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/sandbox/SBAggroBold.ttf")
      format("truetype");
  transform: rotate(0.04deg);
}

.demoModal .modal-popup .modal-content {
  will-change: transform;
}

.demoModal .modal-popup .modal-body {
  padding: 0;
  overflow: hidden;
  height: 365px;
}

.demoModal .modal-popup .Popup-pag {
  position: absolute;
  right: 3rem;
  top: 2rem;
  left: auto;
  bottom: auto;
  width: auto;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  padding: 0.15rem 1rem;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}

.demoModal .modal-popup .Popup-pag .swiper-pagination-current {
  font-weight: var(--Medium);
}

.demoModal .modal-popup .Popup-Slider,
.adPopup .Popup-Slider {
  width: 100%;
  height: 100%;
}

.demoModal .modal-popup .swiper-slide.pop-slide1 > a,
.demoModal .modal-popup .swiper-slide.pop-slide2 > a {
  padding: 3rem 3.5rem 3.5rem;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.demoModal .modal-popup .swiper-slide.pop-slide1 {
  background: url(https://www.flexdaycdn.net/public/images/ticket/modal_bg.png)
    no-repeat center / cover;
}

.demoModal .modal-popup .pop-slide1 .MockupImg {
  position: absolute;
  right: 3.5rem;
  bottom: -100px;
  max-width: 150px;
}

.demoModal .modal-popup .pop-slide1 .Desc {
  font-size: 22px;
  color: #fff;
  font-weight: var(--Medium);
  margin-bottom: 1rem;
}

.demoModal .modal-popup .pop-slide1 .Tit {
  font-size: 47px;
  color: #fff;
  font-weight: var(--Bold);
  line-height: 1.2;
  margin-bottom: 3.5rem;
}

.demoModal .modal-popup .pop-slide1 .Badge {
  font-size: 18px;
  font-weight: 200;
  color: #fff;
  background-color: #1c1c58;
  display: inline-block;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
}

.demoModal .modal-popup .pop-slide1 .bold {
  font-size: 1.1em;
  font-weight: var(--Semibold);
}

.demoModal .modal-popup .swiper-slide.pop-slide2 {
  background: url(https://www.flexdaycdn.net/public/images/ticket/modal_bg2.png)
    no-repeat center top / cover;
}

.demoModal .modal-popup .pop-slide2 .MockupImg {
  position: absolute;
  right: 0.25rem;
  bottom: 1.5rem;
  max-width: 200px;
}

.demoModal .modal-popup .pop-slide2 .Tit {
  font-family: "SBAggro", "Pretendard", sans-serif;
  font-size: 47px;
  color: #fff;
  font-weight: var(--Bold);
  line-height: 1.2;
  margin-bottom: 1rem;
}

.demoModal .modal-popup .pop-slide2 .Tit .span {
  display: flex;
  align-items: center;
  color: #effea5;
}

.demoModal .modal-popup .pop-slide2 .Tit .span .apple {
  width: 60px;
  margin: -80px -15px 0;
  z-index: 10;
}

.demoModal .modal-popup .pop-slide2 .Desc {
  font-size: 22px;
  color: #fff;
  font-weight: var(--Medium);
  margin-bottom: 2rem;
}

.demoModal .modal-popup .pop-slide2 .Badge {
  font-size: 18px;
  font-weight: 200;
  color: #fff;
  background-color: #19494b;
  display: inline-block;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
}

.demoModal .modal-popup .pop-slide2 .bold {
  font-size: 1.1em;
  font-weight: var(--Semibold);
}

.demoModal .modal-popup .pop-slide2 .Badge .arrow {
  width: 13px;
  margin-top: -5px;
  margin-left: 0.75rem;
}

.demoModal .modal-popup .modal-footer {
  background-color: #fff;
  border-radius: 0;
  padding: 0;
  border-top: 1px solid var(--ColorTypeD);
}

.demoModal .modal-popup .modal-footer .btn {
  padding: 1.75rem 0.5rem;
  border-right: 1px solid var(--ColorTypeD);
  border-radius: 0;
  margin: 0;
}

.demoModal .modal-popup .modal-footer .btn:last-child {
  border-right: 0;
}

@media screen and (max-width: 599px) {
  .demoModal .modal-popup .modal-body {
    height: 275px;
  }

  .demoModal .modal-popup .modal-content {
    border-radius: 2rem 2rem 0 0;
  }

  .demoModal .modal-popup .Popup-pag {
    right: 1.5rem;
    top: 1.25rem;
    padding: 0.15rem 0.75rem;
    font-size: 12px;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide1 > a,
  .demoModal .modal-popup .swiper-slide.pop-slide2 > a {
    padding: 2.5rem 2rem 3rem;
  }

  .demoModal .modal-popup .pop-slide1 .MockupImg {
    right: 1.5rem;
    bottom: -60px;
    max-width: 110px;
  }

  .demoModal .modal-popup .pop-slide1 .Desc {
    font-size: 16px;
    font-weight: var(--Regular);
    margin-bottom: 0.75rem;
  }

  .demoModal .modal-popup .pop-slide1 .Tit {
    font-size: 34px;
    margin-bottom: 2.5rem;
  }

  .demoModal .modal-popup .pop-slide1 .Badge {
    font-size: 14px;
    padding: 0.5rem 1.25rem;
  }

  .demoModal .modal-popup .pop-slide2 .MockupImg {
    right: 0;
    bottom: 1rem;
    max-width: 160px;
  }

  .demoModal .modal-popup .pop-slide2 .Tit {
    font-size: 34px;
  }

  .demoModal .modal-popup .pop-slide2 .Tit .span .apple {
    width: 40px;
    margin: -50px -10px 0;
    z-index: 10;
  }

  .demoModal .modal-popup .pop-slide2 .Desc {
    font-size: 16px;
    font-weight: var(--Regular);
    margin-bottom: 1.25rem;
  }

  .demoModal .modal-popup .pop-slide2 .Badge {
    font-size: 14px;
    padding: 0.5rem 1.25rem;
  }

  .demoModal .modal-popup .pop-slide2 .Badge .arrow {
    width: 10px;
    margin-top: -3px;
    margin-left: 0.6rem;
  }

  .demoModal .modal-popup .modal-footer .btn {
    padding: 1.2rem 0.5rem;
    border-right: 1px solid var(--ColorTypeD);
  }
}

@media screen and (max-width: 380px) {
  .demoModal .modal-popup .pop-slide2 .MockupImg {
    max-width: 145px;
  }
}

@media screen and (max-width: 320px) {
  .demoModal .modal-popup .modal-body {
    height: 250px;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide1 > a,
  .demoModal .modal-popup .swiper-slide.pop-slide2 > a {
    padding: 2rem 1.5rem 2.5rem;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide1 .MockupImg {
    right: 1.25rem;
    bottom: -50px;
    max-width: 95px;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide1 .Desc {
    font-size: 14px;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide1 .Tit {
    font-size: 30px;
    margin-bottom: 2.5rem;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide1 .Badge {
    font-size: 12.5px;
  }

  .demoModal .modal-popup .pop-slide2 .MockupImg {
    max-width: 110px;
    bottom: 0.5rem;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide2 .Tit {
    font-size: 30px;
    margin-bottom: 0.5rem;
  }

  .demoModal .modal-popup .pop-slide2 .Tit .span .apple {
    width: 35px;
    margin: -45px -10px 0;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide2 .Desc {
    font-size: 14px;
  }

  .demoModal .modal-popup .swiper-slide.pop-slide2 .Badge {
    font-size: 12.5px;
  }
}
