.TIntroPage {
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.TIntroPage .IntroBox {
  margin-top: -1.5rem;
}

.TIntroPage .IntroBox .man {
  max-width: 170px;
  margin: 0 auto -10px;
  display: block;
}

.TIntroPage .IntroContent {
  background-color: #fff;
  border: 1px solid var(--ColorTypeD);
  border-radius: 1.25rem;
  padding: 3rem 1rem;
  text-align: center;
  position: relative;
}

.TIntroPage .IntroContent .Intro-tit {
  font-size: 28px;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
}

.TIntroPage .IntroContent .Intro-sub {
  font-size: 20px;
  color: var(--ColorTypeB);
}

.TIntroPage .IntroContent .Intro-Select {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  gap: 0 3rem;
}

.TIntroPage .IntroContent .Intro-Select .select-item .logo {
  max-width: 140px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-bottom: 1.5rem;
}

.TIntroPage .IntroContent .Intro-Select .select-item .txt {
  font-size: 17px;
  color: var(--ColorTypeB);
  line-height: 1.3;
  font-weight: var(--Medium);
}

.TIntroPage .IntroContent .Intro-Select .select-item .txt .small {
  font-weight: var(--Regular);
}

@media screen and (max-width: 599px) {
  .TIntroPage .IntroBox .man {
    max-width: 165px;
  }

  .TIntroPage .IntroContent .Intro-tit {
    font-size: 25px;
  }

  .TIntroPage .IntroContent .Intro-sub {
    font-size: 17px;
  }

  .TIntroPage .IntroContent .Intro-Select {
    justify-content: space-around;
    margin-top: 3rem;
    gap: initial;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .logo {
    max-width: 100px;
    margin-bottom: 1.25rem;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .txt {
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  .TIntroPage .IntroBox .man {
    max-width: 135px;
  }

  .TIntroPage .IntroContent .Intro-tit {
    font-size: 22px;
  }

  .TIntroPage .IntroContent .Intro-sub {
    font-size: 15px;
  }

  .TIntroPage .IntroContent .Intro-Select {
    margin-top: 2rem;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .logo {
    max-width: 85px;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .txt {
    font-size: 13px;
  }
}

/* ver3  */
.TIntroPage .IntroContent {
  padding: 3rem 2.5rem;
}

.TIntroPage .IntroContent .Intro-sub {
  margin-bottom: 3rem;
}

.TIntroPage .IntroBox {
  margin-top: 0;
  padding: 7rem 0;
}

.TIntroPage .IntroContent .Intro-Selectbox {
  padding-bottom: 3.5rem;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 2.5rem;
}

.TIntroPage .IntroContent .Intro-Selectbox.last {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.TIntroPage .IntroContent .Intro-Selectbox .sel_tit {
  justify-content: center;
  align-items: center;
  gap: 0 1rem;
}

.TIntroPage .IntroContent .Intro-Selectbox .sel_tit .logo {
  max-width: 60px;
}

.TIntroPage .IntroContent .Intro-Selectbox .sel_tit p {
  font-weight: var(--Semibold);
  font-size: 30px;
  margin-bottom: 0;
}

.TIntroPage .IntroContent .Intro-Select {
  margin-top: 2rem;
  justify-content: flex-start;
}

.TIntroPage .IntroContent .Intro-Select .select-item {
  width: calc(50% - 1.5rem);
  position: relative;
}

.TIntroPage .IntroContent .Intro-Select .select-item.new::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: url(https://www.flexdaycdn.net/public/images/ticket_ver3/icon_new.png)
    no-repeat center center / 100%;
  width: 24%;
  height: 0;
  padding-bottom: 24%;
}

.TIntroPage .IntroContent .Intro-Select .select-item .imgbox {
  position: relative;
  margin-bottom: 1rem;
}

.TIntroPage .IntroContent .Intro-Select .select-item .imgbox::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100%;
}

.TIntroPage .IntroContent .Intro-Select .select-item .imgbox > img {
  width: 100%;
  max-width: initial;
  margin-bottom: 0;
  box-shadow: none;
}

.TIntroPage .IntroContent .Intro-Select .select-item .imgbox > .imgtxt {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 95%;
  transform: translate(-50%, -50%);
  font-weight: var(--Extrabold);
  font-size: 26px;
  color: #fff;
}

.TIntroPage .IntroContent .Intro-Select .select-item .tit {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: var(--ColorTypeC);
  gap: 0 0.5rem;
}

.TIntroPage .IntroContent .Intro-Select .select-item .tit > .logo {
  margin-bottom: 0;
  box-shadow: none;
  border-radius: initial;
  max-width: 20px;
}

@media screen and (max-width: 599px) {
  .TIntroPage .IntroBox {
    padding: 4rem 0;
  }

  .TIntroPage .IntroContent {
    padding: 2rem 1.5rem;
  }

  .TIntroPage .IntroContent .Intro-sub {
    margin-bottom: 2rem;
  }

  .TIntroPage .IntroContent .Intro-Selectbox {
    padding-bottom: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .TIntroPage .IntroContent .Intro-Selectbox .sel_tit {
    gap: 0 0.5rem;
  }

  .TIntroPage .IntroContent .Intro-Selectbox .sel_tit .logo {
    max-width: 36px;
  }

  .TIntroPage .IntroContent .Intro-Selectbox .sel_tit p {
    font-size: 20px;
  }

  .TIntroPage .IntroContent .Intro-Select {
    margin-top: 1.5rem;
    gap: 0 2rem;
    padding: 0 0.5rem;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item {
    width: calc(50% - 1rem);
  }

  .TIntroPage .IntroContent .Intro-Select .select-item.new::after {
    right: 5%;
    width: 22%;
    padding-bottom: 22%;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .imgbox {
    margin: 0 auto 0.75rem;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .imgbox > .imgtxt {
    font-size: 17px;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .tit > .logo {
    max-width: 12px;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .tit {
    font-size: 13px;
    gap: 0 0.25rem;
  }
}

@media screen and (max-width: 320px) {
  .TIntroPage .IntroContent .Intro-Select {
    gap: 0 1rem;
    padding: 0;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item {
    width: calc(50% - 0.5rem);
  }

  .TIntroPage .IntroContent .Intro-Select .select-item.new::after {
    right: 0;
    width: 26%;
    padding-bottom: 26%;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .imgbox > .imgtxt {
    font-size: 15px;
  }

  .TIntroPage .IntroContent .Intro-Selectbox .sel_tit .logo {
    max-width: 28px;
  }

  .TIntroPage .IntroContent .Intro-Selectbox .sel_tit p {
    font-size: 16px;
  }

  .TIntroPage .IntroContent .Intro-Select .select-item .tit {
    font-size: 11px;
    gap: 0 0.2rem;
  }
}
