.TPaymentFailurePage .FailureBox  {
    margin-top: 12vh;
}

.TPaymentFailurePage .FailureBox img {
    max-width: 70px;
}

.TPaymentFailurePage .PageTit2 {
    font-size: 30px;
    line-height: 1.3;
}

.TPaymentFailurePage .PageSub2 {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size:18px;
}

.TPaymentFailurePage .PageSub {
    font-weight: var(--Medium);
    color: var(--ColorTypeC);
    font-size:18px;
}

.TPaymentFailurePage .PageSubBox {
    background-color: #F7F7F7;
    padding: 2rem;
    margin-bottom: 3rem;
    text-align: left;
}

.TPaymentFailurePage .PageSubBox .info-txt {
    font-size: 18px;
    color: var(--ColorTypeC);
    text-indent: -11px;
    padding-left: 11px;
    word-break: keep-all;
    margin-bottom: 0.5rem;
}

.TPaymentFailurePage .CancelConfirm {
    gap: 0 10px;
}

.TPaymentFailurePage .fixed-bottom {
    width: 100%;
    max-width: 800px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
}

.TPaymentFailurePage .fixed-bottom .btn {
    height: 70px;
    font-size: 22px;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPaymentFailurePage .FailureBox img {
        max-width: 60px;
    }

    .TPaymentFailurePage .PageTit2 {
        font-size: 25px;
    }

    .TPaymentFailurePage .PageSub {
        font-size:15px;
    }
    .TPaymentFailurePage .PageSub2 {
        font-size:15px;
    }

    .TPaymentFailurePage .fixed-bottom .btn {
        height: 55px;
        font-size: 16px;
    }

    .TPaymentFailurePage .PageSubBox {
        padding: 1.5rem;
        margin-bottom: 2rem;
    }

    .TPaymentFailurePage .PageSubBox .info-txt {
        font-size: 14px;
        margin-bottom: 0.3rem;
    }

}
