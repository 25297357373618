.Banner{
    max-height:200px;
    margin-top:15px;
    margin-bottom : 15px;
    padding-left:5px;
    padding-right: 5px;
}

.TicketPage .StoreTab-wrap .e-top{
    margin-top:1rem;
}