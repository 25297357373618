.TNonMemOrderPage .NonMemOrderBox {
    margin-top: 7vh;
}

.TNonMemOrderPage .NonMemOrderBox .title_box>img {
    max-width: 65px;
}

.TNonMemOrderPage .NonMemOrderBox .info_box {
    margin-top: 2rem;
}

.TNonMemOrderPage .NonMemOrderBox .info_box .info_p {
    font-weight: 400;
    font-size: 18px;
    color: var(--ColorTypeC);
    padding-left: 1rem;
    position: relative;
}

.TNonMemOrderPage .NonMemOrderBox .info_box .info_p+.info_p {
    margin-top: 0.5rem;
}

.TNonMemOrderPage .NonMemOrderBox .info_box .info_p::before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--ColorTypeB);
    position: absolute;
    left: 0;
    top: 0.65em;
}

.TNonMemOrderPage .NonMemOrderBox .info_box .btn-order {
    margin-top: 3rem;
    border-radius: 6px;
    font-size: 22px;
    height: auto;
    padding: 1rem 0.5rem;
}

.TNonMemOrderPage .my_order {
    margin-top: 4rem;
    padding-bottom: 3rem;
}

.TNonMemOrderPage .sub_tit {
    font-weight: 600;
    font-size: 22px;
    color: var(--ColorTypeB);
}

.TNonMemOrderPage .my_order .order_list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem 0;
}

.TNonMemOrderPage .my_order .order_list .order_item {
    background: #F7F7F7;
    padding: 2rem;
    border-radius: 1.75rem;
}

.TNonMemOrderPage .my_order .order_list .order_item .prd_name {
    font-size: 32px;
    color: var(--ColorTypeB);
    word-break: break-word;
    margin-bottom: 0.5rem;
    font-weight: 700;
}

.TNonMemOrderPage .my_order .order_list .order_item .store_name {
    font-weight: var(--Regular);
    font-size: 22px;
    color: #231815;
    word-break: break-word;
}



/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TNonMemOrderPage .NonMemOrderBox .title_box>img {
        max-width: 48px;
    }

    .TNonMemOrderPage .NonMemOrderBox .info_box {
        margin-top: 1rem;
    }

    .TNonMemOrderPage .NonMemOrderBox .info_box .info_p {
        font-size: 13px;
        padding-left: 0.75rem;
    }

    .TNonMemOrderPage .NonMemOrderBox .info_box .info_p::before {
        content: "";
        width: 3px;
        height: 3px;
    }

    .TNonMemOrderPage .NonMemOrderBox .info_box .btn-order {
        margin-top: 2.5rem;
        font-size: 16px;
        padding: 0.75rem 0.5rem;
    }


    .TNonMemOrderPage .my_order {
        margin-top: 3rem;
    }

    .TOrderStatusPage .sub_tit {
        font-size: 17px;
    }

    .TNonMemOrderPage .my_order .order_list {
        margin-top: 0.75rem;
        gap: 1rem 0;
    }

    .TNonMemOrderPage .my_order .order_list .order_item {
        padding: 1.5rem;
        border-radius: 1.3rem;
    }

    .TNonMemOrderPage .my_order .order_list .order_item .prd_name {
        font-size: 22px;
        margin-bottom: 0.25rem;
    }

    .TNonMemOrderPage .my_order .order_list .order_item .store_name {
        font-size: 15px;
    }



}