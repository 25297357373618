/* 23-01-11 추가 */

.TAppleRegisterPage .AppleRegisterItem {
    border-bottom: 10px solid #F8F8F8;
    position: relative;
}

.TAppleRegisterPage .AppleRegisterItem::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: #EDEDED;
}

.TAppleRegisterPage .AppleRegisterBox .InputboxWrap {
    margin-bottom: 5.5rem;
}

.TAppleRegisterPage .AppleRegisterBox .InputboxWrap:last-child {
    margin-bottom: 0;
}

.TAppleRegisterPage .AppleRegisterBox .InputboxWrap .tit {
    font-size: 22px;
    color: var(--ColorTypeB);
    font-weight: var(--Medium);
}

.TAppleRegisterPage .AppleRegisterBox .InputboxWrap .AppleRegister-Inputbox .form-control {
    padding: 1.5rem;
    height: 65px;
    font-size: 17px;
}

.demo .TAppleRegisterPage .AppleRegister-Confirm .btn {
    height: 70px;
    font-size: 22px;
}

.TAppleRegisterPage .AppleRegister-Inputbox.d-flex {
    gap: 0 10px;
}

.TAppleRegisterPage .AppleRegister-Inputbox.d-flex .btn-default {
    width: 160px;
    height: 65px;
    font-size: 22px;
}

.TAppleRegisterPage .AppleRegisterBox .InputboxWrap.done .AppleRegister-Confirm .btn {
    background: var(--ColorTypeD);
    border: 1px solid var(--ColorTypeD);
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .demo .TAppleRegisterPage .AppleRegisterBox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .demo .TAppleRegisterPage .AppleRegisterItem.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .TAppleRegisterPage .AppleRegisterItem .PageTit2 {
        font-size: 20px;
    }

    .TAppleRegisterPage .AppleRegisterItem .PageTit2 img {
        width: 17px;
    }

    .TAppleRegisterPage .AppleRegisterItem .row {
        gap: 0 15px
    }

    .TAppleRegisterPage .AppleRegisterItem .row .prd-info {
        font-size: 16px;
    }

    .TAppleRegisterPage .AppleRegisterItem .row .prd-sub {
        font-size: 16px;
    }

    .TAppleRegisterPage .AppleRegisterBox .infobox {
        padding: 1.5rem;
        margin-bottom: 2rem;
    }

    .TAppleRegisterPage .AppleRegisterBox .infobox .tit {
        font-size: 19px;
        gap: 0 0.4rem;
        margin-bottom: 0.5rem;
    }

    .TAppleRegisterPage .AppleRegisterBox .infobox .tit img {
        width: 20px;
    }

    .TAppleRegisterPage .AppleRegisterBox .infobox .info-txt {
        font-size: 14px;
        margin-bottom: 0.3rem;
    }

    .TAppleRegisterPage .AppleRegisterBox .InputboxWrap {
        margin-bottom: 4rem;
    }

    .TAppleRegisterPage .AppleRegisterBox .InputboxWrap .tit {
        font-size: 18px;
        margin-bottom: 0.75rem;
    }

    .TAppleRegisterPage .AppleRegisterBox .InputboxWrap .AppleRegister-Inputbox .form-control {
        padding: 1.25rem;
        height: 50px;
        font-size: 14px;
    }

    .demo .TAppleRegisterPage .AppleRegister-Confirm .btn {
        height: 55px;
        font-size: 16px;
    }

    .TAppleRegisterPage .AppleRegister-Inputbox.d-flex .btn-default  {
        width: 130px;
        height: 50px;
        font-size: 16px;
    }

}


@media screen and (max-width: 320px) {

    .TAppleRegisterPage .AppleRegister-Inputbox.d-flex .btn-default  {
        width: 110px;
    }

}