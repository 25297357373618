@font-face {
  font-family: "TmoneyRoundWind";
  font-weight: 400;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-Regular.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-Regular.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-Regular.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-Regular.ttf")
      format("truetype");
  font-display: swap;
  transform: rotate(0.04deg);
}

@font-face {
  font-family: "TmoneyRoundWind";
  font-weight: 800;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/tmoney/TmoneyRoundWind-ExtraBold.ttf")
      format("truetype");
  font-display: swap;
  transform: rotate(0.04deg);
}

@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");

/* 
font-family: 'TmoneyRoundWind';
font-family: 'Nanum Pen Script', cursive;
*/

.TPopupPage {
  min-height: 100vh;
  background-color: #4546b0;
  font-family: "TmoneyRoundWind", sans-serif;
}

.TPopupPage .NanumPen {
  font-family: "Nanum Pen Script", "TmoneyRoundWind", sans-serif;
}

.TPopupPage .PopupContents {
  padding: 0 3rem;
}

.TPopupPage .PopupTitle {
  padding-top: 6.5rem;
}

.TPopupPage .PopupTitle .PopupBadge {
  font-size: 22px;
  background-color: #1c1c58;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  padding: 0.25rem 2rem;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.TPopupPage .PopupTitle .PopupTit {
  color: #fff;
  font-size: 40px;
  letter-spacing: -0.1rem;
  line-height: 1.3;
}

.TPopupPage .PopupTitle .PopupTit > .Big {
  font-size: 70px;
  font-weight: 800;
}

.TPopupPage .PopupTitle .PopupTit > .Big > .Badge {
  background-color: #5656c6;
  border-radius: 0.5em;
  padding: 0.75rem 1rem 0.5rem;
  display: inline-block;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
}

.TPopupPage .PopupDate {
  font-size: 35px;
  color: #1a1863;
  margin: 1.75rem 0 1.5rem;
  transform: rotate(0.04deg);
}

.TPopupPage .PopupDate .bold {
  font-weight: 800;
}

.TPopupPage .PopupText {
  font-size: 22px;
  color: #fff;
  transform: rotate(0.04deg);
}

.TPopupPage .PopupText .bold {
  font-weight: 800;
}

.TPopupPage .PopupTxtBox {
  background-color: #fff;
  padding: 3.5rem 1rem;
  border-radius: 2.5rem;
  margin: 3rem 0;
}

.TPopupPage .PopupTxtBox .txt {
  font-size: 20px;
  color: #4546b0;
  transform: rotate(0.04deg);
}

.TPopupPage .PopupTxtBox .txt .bold {
  font-weight: 800;
}

.TPopupPage .PopupTxtBox .txt .Tip {
  display: block;
  font-size: 32px;
  line-height: 1.2;
  color: var(--ColorTypeA);
}

.TPopupPage .PopupTxtBox .txt .small {
  display: inline-block;
}

.TPopupPage .PopupTxtBox .PopupThumBox {
  margin: 3.5rem 0 2.5rem;
}

.TPopupPage .PopupTxtBox .PopupThumBox .name {
  font-size: 18px;
  color: #4546b0;
  font-weight: 800;
  line-height: 1.3;
  margin-top: 1rem;
  transform: rotate(0.04deg);
}

.TPopupPage .PopupTxtBox .PopupThum {
  position: relative;
}

.TPopupPage .PopupTxtBox .PopupThum > img {
  max-height: 180px;
  width: auto;
  margin-right: -1rem;
}

.TPopupPage .PopupNotice {
  padding-bottom: 3rem;
}

.TPopupPage .PopupNotice .tit {
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  transform: rotate(0.04deg);
  margin-bottom: 0.35rem;
}

.TPopupPage .PopupNotice .tit > img {
  max-width: 24px;
}

.TPopupPage .PopupNotice .desc {
  font-size: 17px;
  color: #fff;
  position: relative;
  padding-left: 0.75rem;
  margin-bottom: 0.25rem;
}

.TPopupPage .PopupNotice .desc:last-child {
  margin-bottom: 0;
}

.TPopupPage .PopupNotice .desc::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.6em;
  width: 0.15em;
  height: 0.15em;
  background-color: #fff;
  border-radius: 100%;
}

@media (max-width: 599px) {
  .TPopupPage .PopupContents {
    padding: 0px 1.5rem;
  }

  .TPopupPage .PopupTitle {
    padding-top: 4.5rem;
  }

  .TPopupPage .PopupTitle .PopupBadge {
    font-size: 15px;
    padding: 0.25rem 1.25rem;
  }

  .TPopupPage .PopupTitle .PopupTit {
    font-size: 28px;
  }

  .TPopupPage .PopupTitle .PopupTit > .Big {
    font-size: 45px;
  }

  .TPopupPage .PopupTitle .PopupTit > .Big > .Badge {
    padding: 0.5rem 0.75rem 0.25rem;
    margin-top: 0.5rem;
  }

  .TPopupPage .PopupDate {
    font-size: 25px;
  }

  .TPopupPage .PopupText {
    font-size: 16px;
  }

  .TPopupPage .PopupTxtBox {
    padding: 2.5rem 0.75rem;
    border-radius: 2rem;
    margin: 2rem 0;
  }

  .TPopupPage .PopupTxtBox .txt {
    font-size: 15px;
  }

  .TPopupPage .PopupTxtBox .txt .small {
    font-size: 0.825em;
  }

  .TPopupPage .PopupTxtBox .PopupThumBox {
    margin: 2.5rem 0 1.5rem;
  }

  .TPopupPage .PopupTxtBox .PopupThumBox .name {
    font-size: 14px;
    margin-top: 1rem;
    letter-spacing: -0.5px;
  }

  .TPopupPage .PopupTxtBox .PopupThum > img {
    max-height: 120px;
  }

  .TPopupPage .PopupNotice .tit {
    font-size: 15px;
    gap: 0 0.25rem;
  }

  .TPopupPage .PopupNotice .tit > img {
    max-width: 18px;
  }

  .TPopupPage .PopupNotice .desc {
    font-size: 13px;
    margin-bottom: 0.15rem;
  }

  .TPopupPage .PopupNotice .desc::before {
    top: 0.7em;
  }
}

@media (max-width: 320px) {
  .TPopupPage .PopupTitle .PopupBadge {
    font-size: 13px;
  }

  .TPopupPage .PopupTitle .PopupTit {
    font-size: 25px;
  }

  .TPopupPage .PopupTitle .PopupTit > .Big {
    font-size: 38px;
  }

  .TPopupPage .PopupDate {
    font-size: 25px;
    margin: 1rem 0 0.75rem;
  }

  .TPopupPage .PopupText {
    font-size: 15px;
  }

  .TPopupPage .PopupTxtBox {
    padding: 1.75rem 1.5rem;
  }

  .TPopupPage .PopupTxtBox .txt {
    font-size: 13px;
    word-break: keep-all;
  }

  .TPopupPage .PopupTxtBox .txt br {
    display: none;
  }

  .TPopupPage .PopupTxtBox .PopupThumBox {
    margin: 2rem 0 1.5rem;
  }

  .TPopupPage .PopupTxtBox .PopupThumBox .name {
    font-size: 13px;
  }

  .TPopupPage .PopupTxtBox .PopupThum > img {
    max-height: 110px;
  }

  .TPopupPage .PopupNotice {
    padding-bottom: 2.5rem;
  }
}

/* 팝업 상세 버튼 추가 */

.TPopupPage .PopupBtn {
  background-color: #fff;
}

.TPopupPage .PopupBtn .btn {
  padding: 1.75rem 0.5rem;
  font-size: 20px;
  font-weight: var(--Bold);
  transform: rotate(0.04deg);
  border: 0;
}

.TPopupPage .PopupBtn .btn:hover,
.TPopupPage .PopupBtn .btn:focus {
  border: 0;
}

@media (max-width: 599px) {
  .TPopupPage .PopupBtn .btn {
    padding: 1.25rem 0.5rem;
    font-size: 17px;
  }
}
