/* 23-01-09 추가 */

.TSurveyPage .SurveyBox.border-bottom {
    border-color: #F7F7F7 !important;
}

.TSurveyPage .SurveyBox .tit {
    font-weight: var(--Bold);
    color: var(--ColorTypeB);
    font-size: 30px;
}

.TSurveyPage .SurveyBox .tit .small {
    font-size: 22px;
    vertical-align: middle;
    margin: 0 0.25rem;
    font-weight: var(--Medium);
}

.TSurveyPage .SurveyBox .desc {
    font-size: 20px;
    line-height: 1.5;
    color: #999999;
}

.TSurveyPage .SurveySelect {
    gap: 0 2.5rem;
}

.TSurveyPage .SurveySelect .form-survey {
    text-align: center;
}

.TSurveyPage .SurveySelect .form-survey .form-radio-input {
    display: none;
}

.TSurveyPage .SurveySelect .form-survey .form-radio-label {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
}

.TSurveyPage .SurveySelect .form-survey .form-radio-input:checked + .form-radio-label {
    color: var(--ColorTypeA);
}

.TSurveyPage .SurveySelect .form-survey .form-radio-label .emoji {
    display: block;
    max-width: 70px;
    margin: auto;
    margin-bottom: 1rem;
}

.TSurveyPage .Survey-Confirm {
    margin-top: 8rem;
}

.TSurveyPage .Survey-Confirm .btn {
    height: 70px;
    font-size: 22px;
}


@media screen and (max-width: 599px) {

    .TSurveyPage .SurveyBox .tit {
        font-size: 22px;
    }

    .TSurveyPage .SurveyBox .tit .small {
        font-size: 15px;
        margin: 0 0.1rem;
    }

    .TSurveyPage .SurveyBox .desc {
        font-size: 14px;
    }

    .TSurveyPage .SurveySelect {
        gap: 0 1.5rem;
    }

    .TSurveyPage .SurveySelect .form-survey .form-radio-label {
        font-size: 14px;
    }

    .TSurveyPage .SurveySelect .form-survey .form-radio-label .emoji {
        max-width: 50px;
        margin-bottom: 0.75rem;
    }

    .demo .TSurveyPage .Survey-Confirm .btn {
        height: 55px;
        font-size: 16px;
    }

    .TSurveyPage .Survey-Confirm {
        margin-top: 5rem;
    }

}



@media screen and (max-width: 320px) {

    .TSurveyPage .Survey-Confirm {
        margin-top: 3rem;
    }


}