.TStampRewardPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TStampRewardPage .StampReward-Top {
  position: relative;
  overflow-x: hidden;
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.TStampRewardPage .StampReward-Top::before {
  content: "";
  width: 80rem;
  height: 80rem;
  background-color: #ffdc06;
  position: absolute;
  left: 50%;
  bottom: 0;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.TStampRewardPage .StampReward-Top .stamp-badge {
  font-size: 27px;
  line-height: 1;
  letter-spacing: -0.5px;
  color: var(--ColorTypeB);
  background-color: #fff;
  display: inline-flex;
  padding: 0.5rem 0.75rem 0.4rem;
  border-radius: 30px;
  margin-bottom: 0;
}

.TStampRewardPage .StampReward-Top .stamp-tit {
  font-size: 37px;
  letter-spacing: -0.75px;
  color: var(--ColorTypeB);
  margin: 1.25rem 0 1rem;
}

.TStampRewardPage .StampReward-Top .stamp-desc {
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: -0.45px;
  color: var(--ColorTypeB);
  margin-bottom: 0.5rem;
}

.TStampRewardPage .StampReward-Top .stamp-desc .mob-320 {
  display: none;
}

.TStampRewardPage .StampReward-Top .stamp-date {
  font-size: 20px;
  letter-spacing: -0.35px;
  color: var(--ColorTypeB);
  margin-bottom: 2.5rem;
}

.TStampRewardPage .StampReward-Top .stamp-state {
  justify-content: center;
  align-items: center;
  gap: 0 2rem;
  font-size: 25px;
  line-height: 1.5;
  letter-spacing: -0.45px;
  color: var(--ColorTypeB);
}

.TStampRewardPage .StampReward-Top .stamp-state .tit {
  word-break: break-word;
  max-width: 70%;
}

.TStampRewardPage .StampReward-Top .stamp-state .num .color {
  color: #5938e1;
}

.TStampRewardPage .StampReward-Bottom .stamp-badge {
  font-size: 25px;
  line-height: 1;
  letter-spacing: -0.5px;
  color: var(--ColorTypeB);
  background-color: #ffdc06;
  display: inline-flex;
  padding: 0.75rem 1.5rem 0.5rem;
  border-radius: 30px;
  margin-bottom: 3.5rem;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow {
  justify-content: center;
  align-items: center;
  gap: 0 1rem;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow.row2 {
  flex-direction: row-reverse;
}

.TStampRewardPage .StampReward-Bottom .stamp-step-dump {
  position: relative;
  width: calc((100% - 3rem) / 4);
  padding-bottom: calc((100% - 3rem) / 4 - 4px);
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #fff;
}

.TStampRewardPage .StampReward-Bottom .stamp-step {
  position: relative;
  width: calc((100% - 3rem) / 4);
  padding-bottom: calc((100% - 3rem) / 4 - 4px);
  border: 2px dotted #999999;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #fff;
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row1
  .stamp-step:first-child:nth-last-child(1) {
  margin-right: calc((100% - 3rem) / 4 * 2 + 2rem);
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row1
  .stamp-step:first-child:nth-last-child(2)
  ~ div {
  margin-right: calc((100% - 3rem) / 4 * 1 + 1rem);
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row1
  .stamp-step:first-child:nth-last-child(3)
  ~ div {
  margin-right: 0;
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row2
  .stamp-step:first-child:nth-last-child(1) {
  margin-left: calc((100% - 3rem) / 4 * 2 + 2rem);
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row2
  .stamp-step:first-child:nth-last-child(2)
  ~ div {
  margin-left: calc((100% - 3rem) / 4 * 1 + 2rem);
}

.TStampRewardPage .StampReward-Bottom .stamp-step::after {
  content: "";
  position: absolute;
  left: calc(100% + 3px);
  top: 50%;
  width: 1rem;
  height: 1px;
  background-color: #999999;
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row1
  .stamp-step:last-child::after {
  display: none;
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row2
  .stamp-step:first-child::after {
  display: none;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-weight: var(--Semibold);
  font-size: 35px;
  letter-spacing: -0.5px;
  color: #999999;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: none;
}

.TStampRewardPage .StampReward-Bottom .stamp-step.on .after {
  display: block;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .after img {
  width: calc(100% + 4px + 4px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 50%;
}

.TStampRewardPage .StampReward-Bottom .stamp-step.on .after.get::after {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket/stamp-get.png)
    no-repeat center / 100%;
  position: absolute;
  right: -10%;
  top: -12%;
  width: 45%;
  height: 45%;
}

.TStampRewardPage .StampReward-Bottom .stamp-step.on .after.finish-get::after {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket/stamp-get.png)
    no-repeat center / 100%;
  position: absolute;
  right: -25%;
  bottom: -5%;
  width: 45%;
  height: 45%;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line {
  margin: -11% 0;
  display: flex;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line.line2 {
  justify-content: flex-start;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line.line1 {
  justify-content: flex-end;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line .stamp-line {
  width: 20%;
  padding-bottom: 35%;
  border-radius: 0 150px 150px 0;
  border: 1px solid #999999;
  border-left: 0;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line.line2 .stamp-line {
  border-radius: 150px 0 0 150px;
  border: 1px solid #999999;
  border-right: 0;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .step-txt {
  position: absolute;
  left: 50%;
  top: calc(100% + 0.75rem);
  transform: translateX(-50%);
  font-size: 15px;
  line-height: 1.3;
  letter-spacing: -0.25px;
  color: var(--ColorTypeB);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0 0.25rem;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .step-txt img {
  width: 13px;
  margin-top: 0.1rem;
}

@media screen and (max-width: 599px) {
  .TStampRewardPage .StampReward-Top {
    padding-top: 2rem;
    padding-bottom: 4.5rem;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TStampRewardPage .StampReward-Top::before {
    width: 60rem;
    height: 75rem;
  }

  .TStampRewardPage .StampReward-Top .stamp-badge {
    font-size: 22px;
  }

  .TStampRewardPage .StampReward-Top .stamp-tit {
    font-size: 32px;
    margin: 1rem 0 0.75rem;
  }

  .TStampRewardPage .StampReward-Top .stamp-desc {
    font-size: 17px;
  }

  .TStampRewardPage .StampReward-Top .stamp-date {
    font-size: 15px;
    margin-bottom: 1.5rem;
  }

  .TStampRewardPage .StampReward-Top .stamp-state {
    font-size: 20px;
    gap: 0 1.5rem;
  }

  .TStampRewardPage .StampReward-Bottom {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-badge {
    font-size: 20px;
    margin-bottom: 2.5rem;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .before {
    font-size: 24px;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .step-txt {
    font-size: 12px;
    width: 135%;
    top: calc(100% + 0.5rem);
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .step-txt img {
    width: 10px;
    margin-top: 0.2rem;
  }
}

@media screen and (max-width: 320px) {
  .TStampRewardPage .StampReward-Top .stamp-desc .mob-320 {
    display: block;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .before {
    font-size: 18px;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .step-txt {
    font-size: 10px;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .step-txt img {
    width: 8px;
    margin-top: 0.2rem;
  }
}

.TStampRewardPage .StampReward-Top {
  position: relative;
  overflow-x: hidden;
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.TStampRewardPage .StampReward-Top::before {
  content: "";
  width: 80rem;
  height: 80rem;
  background-color: #ffdc06;
  position: absolute;
  left: 50%;
  bottom: 0;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.TStampRewardPage .StampReward-Top .stamp-badge {
  font-size: 27px;
  line-height: 1;
  letter-spacing: -0.5px;
  color: var(--ColorTypeB);
  background-color: #fff;
  display: inline-flex;
  padding: 0.5rem 0.75rem 0.4rem;
  border-radius: 30px;
  margin-bottom: 0;
}

.TStampRewardPage .StampReward-Top .stamp-tit {
  font-size: 37px;
  letter-spacing: -0.75px;
  color: var(--ColorTypeB);
  margin: 1.25rem 0 1rem;
}

.TStampRewardPage .StampReward-Top .stamp-desc {
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: -0.45px;
  color: var(--ColorTypeB);
  margin-bottom: 0.5rem;
}

.TStampRewardPage .StampReward-Top .stamp-desc .mob-320 {
  display: none;
}

.TStampRewardPage .StampReward-Top .stamp-date {
  font-size: 20px;
  letter-spacing: -0.35px;
  color: var(--ColorTypeB);
  margin-bottom: 2.5rem;
}

.TStampRewardPage .StampReward-Top .stamp-state {
  justify-content: center;
  align-items: center;
  gap: 0 2rem;
  font-size: 25px;
  line-height: 1.5;
  letter-spacing: -0.45px;
  color: var(--ColorTypeB);
}

.TStampRewardPage .StampReward-Top .stamp-state .tit {
  word-break: break-word;
  max-width: 70%;
}

.TStampRewardPage .StampReward-Top .stamp-state .num .color {
  color: #5938e1;
}

.TStampRewardPage .StampReward-Bottom .stamp-badge {
  font-size: 25px;
  line-height: 1;
  letter-spacing: -0.5px;
  color: var(--ColorTypeB);
  background-color: #ffdc06;
  display: inline-flex;
  padding: 0.75rem 1.5rem 0.5rem;
  border-radius: 30px;
  margin-bottom: 3.5rem;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow {
  justify-content: center;
  align-items: center;
  gap: 0 1rem;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow.row2 {
  flex-direction: row-reverse;
}

.TStampRewardPage .StampReward-Bottom .stamp-step {
  position: relative;
  width: calc((100% - 3rem) / 4);
  padding-bottom: calc((100% - 3rem) / 4 - 4px);
  border: 2px dotted #999999;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #fff;
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row1
  .stamp-step:first-child:nth-last-child(1) {
  margin-right: calc((100% - 3rem) / 4 * 2 + 2rem);
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row1
  .stamp-step:first-child:nth-last-child(2)
  ~ div {
  margin-right: calc((100% - 3rem) / 4 * 1 + 1rem);
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row1
  .stamp-step:first-child:nth-last-child(3)
  ~ div {
  margin-right: 0;
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row2
  .stamp-step:first-child:nth-last-child(1) {
  margin-left: calc((100% - 3rem) / 4 * 2 + 2rem);
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row2
  .stamp-step:first-child:nth-last-child(2)
  ~ div {
  margin-left: calc((100% - 3rem) / 4 * 1 + 2rem);
}

.TStampRewardPage .StampReward-Bottom .stamp-step::after {
  content: "";
  position: absolute;
  left: calc(100% + 3px);
  top: 50%;
  width: 1rem;
  height: 1px;
  background-color: #999999;
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row1
  .stamp-step:last-child::after {
  display: none;
}

.TStampRewardPage
  .StampReward-Bottom
  .stamp-stepRow.row2
  .stamp-step:first-child::after {
  display: none;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-weight: var(--Semibold);
  font-size: 35px;
  letter-spacing: -0.5px;
  color: #999999;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: none;
}

.TStampRewardPage .StampReward-Bottom .stamp-step.on .after {
  display: block;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .after img {
  width: calc(100% + 4px + 4px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.TStampRewardPage .StampReward-Bottom .stamp-step.on .after.get::after {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket/stamp-get.png)
    no-repeat center / 100%;
  position: absolute;
  right: -10%;
  top: -12%;
  width: 45%;
  height: 45%;
}

.TStampRewardPage .StampReward-Bottom .stamp-step.on .after.finish-get::after {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket/stamp-get.png)
    no-repeat center / 100%;
  position: absolute;
  right: -25%;
  bottom: -5%;
  width: 45%;
  height: 45%;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line {
  margin: -11% 0;
  display: flex;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line.line2 {
  justify-content: flex-start;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line.line1 {
  justify-content: flex-end;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line .stamp-line {
  width: 20%;
  padding-bottom: 35%;
  border-radius: 0 150px 150px 0;
  border: 1px solid #999999;
  border-left: 0;
}

.TStampRewardPage .StampReward-Bottom .stamp-stepRow-Line.line2 .stamp-line {
  border-radius: 150px 0 0 150px;
  border: 1px solid #999999;
  border-right: 0;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .step-txt {
  position: absolute;
  left: 50%;
  top: calc(100% + 0.75rem);
  transform: translateX(-50%);
  font-size: 15px;
  line-height: 1.3;
  letter-spacing: -0.25px;
  color: var(--ColorTypeB);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0 0.25rem;
}

.TStampRewardPage .StampReward-Bottom .stamp-step .step-txt img {
  width: 13px;
  margin-top: 0.1rem;
}

@media screen and (max-width: 599px) {
  .TStampRewardPage .StampReward-Top {
    padding-top: 2rem;
    padding-bottom: 4.5rem;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TStampRewardPage .StampReward-Top::before {
    width: 60rem;
    height: 75rem;
  }

  .TStampRewardPage .StampReward-Top .stamp-badge {
    font-size: 22px;
  }

  .TStampRewardPage .StampReward-Top .stamp-tit {
    font-size: 32px;
    margin: 1rem 0 0.75rem;
  }

  .TStampRewardPage .StampReward-Top .stamp-desc {
    font-size: 17px;
  }

  .TStampRewardPage .StampReward-Top .stamp-date {
    font-size: 15px;
    margin-bottom: 1.5rem;
  }

  .TStampRewardPage .StampReward-Top .stamp-state {
    font-size: 20px;
    gap: 0 1.5rem;
  }

  .TStampRewardPage .StampReward-Bottom {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-badge {
    font-size: 20px;
    margin-bottom: 2.5rem;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .before {
    font-size: 24px;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .step-txt {
    font-size: 12px;
    width: 135%;
    top: calc(100% + 0.5rem);
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .step-txt img {
    width: 10px;
    margin-top: 0.2rem;
  }
}

@media screen and (max-width: 320px) {
  .TStampRewardPage .StampReward-Top .stamp-desc .mob-320 {
    display: block;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .before {
    font-size: 18px;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .step-txt {
    font-size: 10px;
  }

  .TStampRewardPage .StampReward-Bottom .stamp-step .step-txt img {
    width: 8px;
    margin-top: 0.2rem;
  }
}

/* ver2 */

/*창원단감아삭체*/
@font-face {
  font-family: "CWDangamAsac-Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/CWDangamAsac-Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
  transform: rotate(0.04deg);
}

.CWDangamAsac {
  font-family: "CWDangamAsac-Bold";
}

.TStampRewardPage .StampReward-Top .stamp-badge {
  font-size: 90px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #fff;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  margin-bottom: 4rem;
  padding-right: 12px;
  transform: skewY(-3deg);
  text-shadow: -3px 0px #000000, 0px -3px #000000, -3px -3px #000000,
    -3px 3px #000000, 3px 0px #000000, 0px 3px #000000, 3px 3px #000000,
    3px -3px #000000, -2px 4px #000000, -1px 5px #000000, 0px 6px #000000,
    1px 7px #000000, 2px 8px #000000, 3px 9px #000000, 4px 10px #000000,
    5px 11px #000000, 6px 12px #000000, 7px 13px #000000, 8px 14px #000000,
    9px 15px #000000, 10px 16px #000000, 11px 17px #000000, 12px 18px #000000,
    3px 0px #000000, 4px 0px #000000, 5px 1px #000000, 6px 2px #000000,
    7px 3px #000000, 8px 4px #000000, 9px 5px #000000, 10px 6px #000000,
    11px 7px #000000, 12px 8px #000000, 13px 9px #000000, 14px 10px #000000,
    17px 13px #000000, 18px 18px #000000, 20px 25px 22px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 599px) {
  .TStampRewardPage .StampReward-Top .stamp-badge {
    font-size: 60px;
    margin-bottom: 3rem;
    padding-right: 6px;
    text-shadow: -2px 0px #000000, 0px -2px #000000, -2px -2px #000000,
      -2px 2px #000000, 2px 0px #000000, 0px 2px #000000, 2px 2px #000000,
      2px -2px #000000, -2px 1px #000000, -1px 2px #000000, 0px 3px #000000,
      1px 4px #000000, 2px 5px #000000, 3px 6px #000000, 4px 7px #000000,
      5px 8px #000000, 6px 9px #000000, 7px 10px #000000, 8px 11px #000000,
      9px 12px #000000, 1px 0px #000000, 2px 0px #000000, 3px 0px #000000,
      4px 1px #000000, 5px 2px #000000, 6px 3px #000000, 7px 4px #000000,
      8px 5px #000000, 9px 6px #000000, 10px 7px #000000, 11px 8px #000000,
      11px 9px #000000, 12px 11px #000000, 12px 12px #000000,
      12px 18px 20px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 320px) {
  .TStampRewardPage .StampReward-Top .stamp-badge {
    font-size: 50px;
    margin-bottom: 2.5rem;
  }
}
