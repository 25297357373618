.TGiftConfirmPage {
    position: relative;
}

.TGiftConfirmPage .Gift-title {
    margin-top: 3rem;
    margin-bottom: 4rem;
}

.TGiftConfirmPage .Gift-title img {
    max-width: 75px;
}

.TGiftConfirmPage .PageTit2 {
    font-size: 30px;
    line-height: 1.3;
}

.TGiftConfirmPage .GiftBox-inner {
    background-color: #fff;
    padding-top: 2rem;
    padding-bottom: 13rem;
    box-shadow: 0px 5px 8px 3px rgb(0 0 0 / 5%);
    position: relative;
}

.TGiftConfirmPage .GiftBox .Gift-infobox {
    padding: 0 2rem;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-item {
    border-top: 1px solid var(--ColorTypeD);
    padding: 2rem 1rem;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-item>.title {
    font-size: 25px;
    font-weight: var(--Medium);
    margin-bottom: 1rem;
    color: var(--ColorTypeB);
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .info-row {
    display: flex;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .info-row+.info-row {
    margin-top: 0.5rem;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .info-row .tit {
    font-size: 22px;
    font-weight: var(--Medium);
    color: #999999;
    width: 40%;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .info-row .desc {
    font-size: 22px;
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    width: 60%;
    text-align: right;
    word-break: break-word;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    align-items: center;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns .imgbox {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 5px;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns .imgbox.kakao {
    background: #FEE500
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns .imgbox.naver {
    background: #00BF18;
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns .imgbox.apple {
    background: var(--ColorTypeB);
}

.TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns .imgbox .sns-icon {
    max-width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.TGiftConfirmPage .GiftBox .GiftBox-bg {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 104.8%;
}

.TGiftConfirmPage .Gift-Confirm {
    display: flex;
    gap: 0 1rem;
    padding: 0 2rem;
    margin-top: 2rem;
}

.demo .TGiftConfirmPage .Gift-Confirm .btn {
    height: 70px;
    font-size: 22px;
}

.demo .TGiftConfirmPage .Gift-Confirm .btn.btn-light {
    border-color: var(--ColorTypeB);
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TGiftConfirmPage .GiftBox-inner {
        padding-bottom: 20vh;
    }

    .TGiftConfirmPage .Gift-title {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    .TGiftConfirmPage .Gift-title img {
        max-width: 50px;
        margin-bottom: 1rem !important;
    }

    .TGiftConfirmPage .PageTit2 {
        font-size: 22px;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox {
        padding: 0px 1.5rem;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox .info-item {
        padding: 1.5rem 0.5rem;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox .info-item>.title {
        font-size: 18px;
        margin-bottom: 0.5rem;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .info-row+.info-row {
        margin-top: 0.25rem;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .info-row .tit {
        font-size: 15px;
        width: 35%;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .info-row .desc {
        font-size: 15px;
        width: 65%;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns {
        gap: 0.4rem;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns .imgbox {
        width: 18px;
        height: 18px;
    }

    .TGiftConfirmPage .GiftBox .Gift-infobox .info-contents .sns .imgbox .sns-icon {
        max-width: 10px;
    }

    .TGiftConfirmPage .Gift-Confirm {
        padding: 0px 1.5rem;
        margin-top: 1rem;
    }

    .demo .TGiftConfirmPage .Gift-Confirm .btn {
        height: 45px;
        font-size: 16px;
    }


}


/* 23.05.09 수정 */
.TGiftConfirmPage .GiftBox-inner {
    padding-bottom: 20rem;
}
@media screen and (max-width: 700px) {
    .TGiftConfirmPage .GiftBox-inner {
        padding-bottom: 14rem;
    }
}
@media screen and (max-width: 599px) {
    .TGiftConfirmPage .GiftBox-inner {
        padding-bottom: 20vh;
    }
}