/* 23-01-09 추가 */

.TwalletPage {
  position: relative;
}

.TwalletPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.TwalletPage .tab-content {
  padding-top: 0;
}

.TwalletPage .PayCateTab .nav-pills {
  background-color: #fff;
  border-bottom: 1px solid var(--ColorTypeE);
  padding-top: 2rem;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.TwalletPage .PayCateTab .nav-pills .nav-item {
  width: 50%;
}

.TwalletPage .PayCateBtn {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeD);
  border-radius: 0;
  --bs-nav-link-padding-x: 0.5rem;
  position: relative;
  line-height: 1.15;
  width: 100%;
  padding-bottom: 1.25rem;
}

.TwalletPage .nav-pills .PayCateBtn.active {
  color: var(--ColorTypeB);
  border-radius: 0;
  background-color: transparent;
  border-bottom: 2px solid var(--ColorTypeA);
}

.TwalletPage .PayCateBtn span {
  position: relative;
}

.TwalletPage .PayListItem {
  margin-bottom: 1.5rem;
}

.TwalletPage .PayListItem .infobox {
  position: relative;
  padding: 2rem;
  border-radius: 1.75rem;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.06);
}

.TwalletPage .PayPrdList .PayListItem .infobox .prd-date {
  font-weight: 500;
  font-size: 22px;
  color: var(--ColorTypeB);
  margin-bottom: 0.75rem;
}

.TwalletPage .PayListItem .infobox .store-name {
  font-weight: var(--Regular);
  font-size: 22px;
  color: #231815;
  word-break: break-word;
  margin-bottom: 0;
}

.TwalletPage .PayListItem .infobox .prd-name {
  font-size: 34px;
  color: var(--ColorTypeB);
  word-break: break-word;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.TwalletPage .PayListItem .btnbox {
  display: flex;
  justify-content: space-between;
  gap: 0 1rem;
  margin-top: 1.5rem;
}

.TwalletPage .PayListItem .btnbox .btn {
  width: calc(50% - 0.5rem);
  border-radius: 6px;
  font-size: 24px;
  height: 65px;
}

.TwalletPage .PayListItem .btnbox .btn-gift {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 0.5rem;
}

.TwalletPage .PayListItem .btnbox .btn-gift > img {
  max-width: 26px;
}

.TwalletPage .PayListItem .infobox .prd-stamp {
  position: absolute;
  right: 5%;
  top: 0;
  width: 35%;
  max-width: 133px;
}

.TwalletPage .PayListItem.used .infobox .txtbox .prd-name,
.TwalletPage .PayListItem.used .infobox .txtbox .store-name,
.TwalletPage .PayListItem.used .infobox .txtbox .prd-date {
  opacity: 0.3;
}

.TwalletPage .PayListItem.survey-done .infobox .btn-survey {
  opacity: 0.3;
  pointer-events: none;
}

.TwalletPage .PayPrdList.gift {
  margin-top: 3.5rem;
}

.TwalletPage .PayPrdList.gift .PayListItem:last-child {
  margin-bottom: 0;
}

.TwalletPage .PayPrdList.gift .giftTxt {
  font-size: 30px;
  font-weight: 600;
  color: var(--ColorTypeB);
  margin-bottom: 1.5rem;
}

.TwalletPage .PayListItem.cooking .btnbox {
  display: none;
}

.TwalletPage .PayListItem .orderbox {
  display: none;
}

.TwalletPage .PayListItem.cooking .orderbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d3d3d3;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.TwalletPage .PayListItem.cooking .orderbox .state {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 0.5rem;
  font-weight: var(--Medium);
  font-size: 22px;
  color: #b2b2b2;
}

.TwalletPage .PayListItem.cooking .orderbox .state .dot {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background: #b2b2b2;
}

.TwalletPage .PayListItem.cooking .orderbox .state.on {
  color: #34c759;
}

.TwalletPage .PayListItem.cooking .orderbox .state.on .dot {
  background: #58d678;
}

.TwalletPage .PayListItem.cooking .orderbox .state.pickup {
  color: #395eec;
}

.TwalletPage .PayListItem.cooking .orderbox .state.pickup .dot {
  background: #395eec;
}

.TwalletPage .PayListItem.cooking .orderbox .state.done {
  color: #ec1556;
}

.TwalletPage .PayListItem.cooking .orderbox .state.done .dot {
  background: #ec1556;
}

.TwalletPage .PayListItem.cooking .orderbox .state.cancel {
  color: #ec1515;
}

.TwalletPage .PayListItem.cooking .orderbox .state.cancel .dot {
  background: #ec1515;
}

.TwalletPage .PayListItem.cooking .orderbox .link .btn-ordercheck {
  font-size: 24px;
  color: var(--ColorTypeB);
  display: flex;
  gap: 0 1rem;
  align-items: center;
  font-weight: var(--Medium);
}

.TwalletPage .PayListItem.cooking .orderbox .link .btn-ordercheck > img {
  max-width: 10%;
}

.demoModal.modal-prdorder .modal-dialog.modal-sm {
  --bs-modal-width: 100%;
  width: 100%;
  transform: translateY(50px);
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  height: auto;
  display: block;
}

.demoModal.modal.modal-prdorder.show .modal-dialog.modal-sm {
  transform: none;
}

.demoModal.modal-prdorder .modal-content {
  border: none;
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 0;
  border-radius: 0;
  background: #fff;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  overflow: hidden;
  will-change: transform;
}

.demoModal.modal-prdorder .modal-bar {
  padding: 1rem 0;
}

.demoModal.modal-prdorder .modal-bar .bar {
  background-color: #cccccc;
  border-radius: 30px;
  margin: auto;
  width: 15%;
  height: 0.45rem;
}

.demoModal.modal-prdorder .modal-header {
  padding: 3rem 0.5rem 3.5rem;
}

.demoModal.modal-prdorder .modal-header .modal-title {
  font-size: 35px;
  text-align: center;
}

.demoModal.modal-prdorder .modal-header .modal-title span {
  font-weight: var(--Semibold);
}

.demoModal.modal-prdorder .modal-body {
  padding: 0 4rem 4rem;
}

.demoModal.modal-prdorder .desc_list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
  padding: 0 1rem;
}

.demoModal.modal-prdorder .desc_list .list {
  margin-bottom: 0;
  position: relative;
  padding-left: 1rem;
  font-weight: 400;
  font-size: 18px;
  color: var(--ColorTypeC);
  word-break: break-word;
}

.demoModal.modal-prdorder .desc_list .list::before {
  content: "";
  width: 0.2em;
  height: 0.2em;
  position: absolute;
  left: 0;
  top: 0.65em;
  background-color: var(--ColorTypeC);
  border-radius: 50%;
}

.demoModal.modal-prdorder .slide_btnbox {
  position: relative;
  margin-top: 3.5rem;
}

.demoModal.modal-prdorder .slide_btnbox .slide_circle {
  height: 75px;
  width: 75px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demoModal.modal-prdorder .slide_btnbox .slide_circle > img {
  max-width: 16%;
}

.demoModal.modal-prdorder .slide_btnbox .txt {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: var(--Medium);
  font-size: 24px;
  color: var(--ColorTypeC);
}

/* 모바일 반응형 */

@media screen and (max-width: 1024px) {
  .TwalletPage::before {
    border-left: 0px;
    border-right: 0px;
  }

  .TwalletPage .PayCateTab .nav-pills {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .TwalletPage .PayCateBtn {
    font-size: 17px;
    padding-bottom: 1rem;
  }

  .TwalletPage .PayListItem {
    margin-bottom: 1.25rem;
  }

  .TwalletPage .PayListItem .infobox {
    padding: 1.5rem;
    border-radius: 1.3rem;
  }

  .TwalletPage .PayListItem .infobox .store-name {
    font-size: 15px;
  }

  .TwalletPage .PayListItem .infobox .prd-name {
    font-size: 24px;
    margin-bottom: 0.25rem;
  }

  .TwalletPage .PayListItem .btnbox {
    gap: 0 0.8rem;
    margin-top: 1rem;
  }

  .TwalletPage .PayListItem .btnbox .btn {
    width: calc(50% - 0.4rem);
    font-size: 16px;
    height: 45px;
  }

  .TwalletPage .PayListItem .btnbox .btn-gift {
    gap: 0 0.25rem;
  }

  .TwalletPage .PayListItem .btnbox .btn-gift > img {
    max-width: 18px;
  }

  .TwalletPage .PayPrdList.gift {
    margin-top: 3rem;
  }

  .TwalletPage .PayPrdList.gift .giftTxt {
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .TwalletPage .PayPrdList .PayListItem .infobox .prd-date {
    font-size: 15px;
    margin-bottom: 0.5rem;
  }

  .TwalletPage .PayListItem.cooking .orderbox {
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .TwalletPage .PayListItem.cooking .orderbox .state {
    gap: 0 0.4rem;
    font-size: 16px;
  }

  .TwalletPage .PayListItem.cooking .orderbox .state .dot {
    width: 12px;
    height: 12px;
  }

  .TwalletPage .PayListItem.cooking .orderbox .link .btn-ordercheck {
    font-size: 16px;
    gap: 0 0.75rem;
  }

  .TwalletPage .PayListItem .infobox .prd-stamp {
    max-width: 100px;
  }

  .demoModal.modal-prdorder .modal-bar {
    padding: 0.8rem 0 0.5rem;
  }

  .demoModal.modal-prdorder .modal-bar .bar {
    height: 0.3rem;
    width: 15%;
    height: 0.28rem;
  }

  .demoModal.modal-prdorder .modal-header {
    padding: 2rem 0.5rem 2.5rem;
  }

  .demoModal.modal-prdorder .modal-header .modal-title {
    font-size: 24px;
  }

  .demoModal.modal-prdorder .modal-body {
    padding: 0 2rem 3rem;
  }

  .demoModal.modal-prdorder .desc_list .list {
    padding-left: 0.75rem;
    font-size: 13px;
  }

  .demoModal.modal-prdorder .desc_list .list::before {
    width: 0.24em;
    height: 0.24em;
  }

  .demoModal.modal-prdorder .slide_btnbox {
    margin-top: 2.75rem;
    /* padding: 5px; */
  }

  .demoModal.modal-prdorder .slide_btnbox .slide_circle {
    height: 55px;
    width: 55px;
  }

  .demoModal.modal-prdorder .slide_btnbox .txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  .TwalletPage .PayListItem .infobox {
    padding: 1.25rem;
  }

  .TwalletPage .PayListItem .infobox .prd-name {
    font-size: 20px;
  }

  .TwalletPage .PayListItem .infobox .store-name {
    font-size: 13px;
  }

  .TwalletPage .PayListItem .btnbox .btn {
    font-size: 14px;
    height: 40px;
  }

  .TwalletPage .PayListItem .btnbox .btn-gift > img {
    max-width: 15px;
  }

  .TwalletPage .PayListItem .infobox .prd-stamp {
    max-width: 80px;
  }

  .demoModal.modal-prdorder .modal-header {
    padding: 1.5rem 0.5rem 2rem;
  }

  .demoModal.modal-prdorder .modal-body {
    padding: 0 1rem 2.5rem;
  }

  .demoModal.modal-prdorder .desc_list .list {
    font-size: 12px;
  }

  .demoModal.modal-prdorder .slide_btnbox {
    margin-top: 2.25rem;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.loader {
  margin: auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.85em solid rgba(0, 0, 0, 0.2);
  border-right: 0.85em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.85em solid rgba(0, 0, 0, 0.2);
  border-left: 0.85em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 599px) {
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 3.25em;
    height: 3.25em;
  }

  .loader {
    border-top: 0.5em solid rgba(0, 0, 0, 0.2);
    border-right: 0.5em solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
    border-left: 0.5em solid #ffffff;
  }
}
