

.TGiftPage .GiftItem {
    border-bottom: 10px solid #F8F8F8;
    position: relative;
}

.TGiftPage .GiftItem::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: #EDEDED;
}

.TGiftPage .GiftItem .PageTit2 {
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
    font-size: 28px;
}

.TGiftPage .GiftItem .PageTit2 img {
    margin-right: 0;
    margin-left: 0.75rem;
    width: 28px;
}

.TGiftPage .GiftItem .row {
    gap: 0 30px
}

.TGiftPage .GiftItem .row .prd-info {
    font-size: 22px;
    margin-bottom: 0;
    color: var(--ColorTypeC);
    font-weight: var(--Medium);
}

.TGiftPage .GiftItem .row .prd-sub {
    font-size: 22px;
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    margin-bottom: 0.5rem;
    text-align: right;
    word-break: break-word;
}

.TGiftPage .GiftBox .infobox {
    background-color: #F7F7F7;
    padding: 2rem;
    margin-bottom: 3rem;
}

.TGiftPage .GiftBox .infobox .tit {
    font-size: 24px;
    font-weight: var(--Medium);
    color: var(--ColorTypeC);
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    margin-bottom: 0.75rem;
}

.TGiftPage .GiftBox .infobox .tit img {
    width: 24px;
}

.TGiftPage .GiftBox .infobox .info-txt {
    font-size: 18px;
    color: var(--ColorTypeC);
    text-indent: -11px;
    padding-left: 11px;
    word-break: keep-all;
    margin-bottom: 0.5rem;
}

.TGiftPage .GiftBox .recipient {
    margin-bottom: 4rem;
}

.TGiftPage .GiftBox .recipient .tit {
    font-size: 22px;
    color: var(--ColorTypeB);
    font-weight: var(--Medium);
}

.TGiftPage .GiftBox .recipient .Gift-Inputbox .form-control {
    padding: 1.5rem;
    height: 65px;
    font-size: 17px;
}

.demo .TGiftPage .Gift-Confirm .btn {
    height: 70px;
    font-size: 22px;
}

.demo .TGiftPage .Gift-Confirm .btn.btn-default:focus {
    background-color:var(--ColorTypeB);
    color: #fff;
    border-color: var(--ColorTypeB);
    box-shadow: none;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .demo .TGiftPage .GiftBox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .demo .TGiftPage .GiftItem.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }   

    .TGiftPage .GiftItem .PageTit2 {
        font-size: 20px;
    }

    .TGiftPage .GiftItem .PageTit2 img {
        margin-left: 0.5rem;
        width: 17px;
    }

    .TGiftPage .GiftItem .row {
        gap: 0 15px
    }

    .TGiftPage .GiftItem .row .prd-info {
        font-size: 16px;
    }

    .TGiftPage .GiftItem .row .prd-sub {
        font-size: 16px;
    }

    .TGiftPage .GiftBox .infobox {
        padding: 1.5rem;
        margin-bottom: 2rem;
    }

    .TGiftPage .GiftBox .infobox .tit {
        font-size: 19px;
        gap: 0 0.4rem;
        margin-bottom: 0.5rem;
    }

    .TGiftPage .GiftBox .infobox .tit img {
        width: 20px;
    }

    .TGiftPage .GiftBox .infobox .info-txt {
        font-size: 14px;
        margin-bottom: 0.3rem;
    }

    .TGiftPage .GiftBox .recipient {
        margin-bottom: 3rem;
    }

    .TGiftPage .GiftBox .recipient .tit {
        font-size: 18px;
        margin-bottom: 0.75rem;
    }
    
    .TGiftPage .GiftBox .recipient .Gift-Inputbox .form-control {
        padding: 1.25rem;
        height: 50px;
        font-size: 14px;
    }
    
    .demo .TGiftPage .Gift-Confirm .btn {
        height: 55px;
        font-size: 16px;
    }

}





.TGiftPage .GiftItem {
    border-bottom: 10px solid #F8F8F8;
    position: relative;
}

.TGiftPage .GiftItem::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: #EDEDED;
}

.TGiftPage .GiftItem .PageTit2 {
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
    font-size: 28px;
}

.TGiftPage .GiftItem .PageTit2 img {
    margin-right: 0;
    margin-left: 0.75rem;
    width: 28px;
}

.TGiftPage .GiftItem .row {
    gap: 0 30px
}

.TGiftPage .GiftItem .row .prd-info {
    font-size: 22px;
    margin-bottom: 0;
    color: var(--ColorTypeC);
    font-weight: var(--Medium);
}

.TGiftPage .GiftItem .row .prd-sub {
    font-size: 22px;
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    margin-bottom: 0.5rem;
    text-align: right;
    word-break: break-word;
}

.TGiftPage .GiftBox .infobox {
    background-color: #F7F7F7;
    padding: 2rem;
    margin-bottom: 3rem;
}

.TGiftPage .GiftBox .infobox .tit {
    font-size: 24px;
    font-weight: var(--Medium);
    color: var(--ColorTypeC);
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    margin-bottom: 0.75rem;
}

.TGiftPage .GiftBox .infobox .tit img {
    width: 24px;
}

.TGiftPage .GiftBox .infobox .info-txt {
    font-size: 18px;
    color: var(--ColorTypeC);
    text-indent: -11px;
    padding-left: 11px;
    word-break: keep-all;
    margin-bottom: 0.5rem;
}

.TGiftPage .GiftBox .recipient {
    margin-bottom: 4rem;
}

.TGiftPage .GiftBox .recipient .tit {
    font-size: 22px;
    color: var(--ColorTypeB);
    font-weight: var(--Medium);
}

.TGiftPage .GiftBox .recipient .Gift-Inputbox .form-control {
    padding: 1.5rem;
    height: 65px;
    font-size: 17px;
}

.demo .TGiftPage .Gift-Confirm .btn {
    height: 70px;
    font-size: 22px;
}

.demo .TGiftPage .Gift-Confirm .btn.btn-default:focus {
    background-color:var(--ColorTypeB);
    color: #fff;
    border-color: var(--ColorTypeB);
    box-shadow: none;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .demo .TGiftPage .GiftBox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .demo .TGiftPage .GiftItem.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }   

    .TGiftPage .GiftItem .PageTit2 {
        font-size: 20px;
    }

    .TGiftPage .GiftItem .PageTit2 img {
        margin-left: 0.5rem;
        width: 17px;
    }

    .TGiftPage .GiftItem .row {
        gap: 0 15px
    }

    .TGiftPage .GiftItem .row .prd-info {
        font-size: 16px;
    }

    .TGiftPage .GiftItem .row .prd-sub {
        font-size: 16px;
    }

    .TGiftPage .GiftBox .infobox {
        padding: 1.5rem;
        margin-bottom: 2rem;
    }

    .TGiftPage .GiftBox .infobox .tit {
        font-size: 19px;
        gap: 0 0.4rem;
        margin-bottom: 0.5rem;
    }

    .TGiftPage .GiftBox .infobox .tit img {
        width: 20px;
    }

    .TGiftPage .GiftBox .infobox .info-txt {
        font-size: 14px;
        margin-bottom: 0.3rem;
    }

    .TGiftPage .GiftBox .recipient {
        margin-bottom: 3rem;
    }

    .TGiftPage .GiftBox .recipient .tit {
        font-size: 18px;
        margin-bottom: 0.75rem;
    }
    
    .TGiftPage .GiftBox .recipient .Gift-Inputbox .form-control {
        padding: 1.25rem;
        height: 50px;
        font-size: 14px;
    }
    
    .demo .TGiftPage .Gift-Confirm .btn {
        height: 55px;
        font-size: 16px;
    }

}


/* ver2 */

.demoModal.modal-gift .gift-member {
    width: 90%;
    max-width: 300px;
    margin: 1rem auto;
}

.demoModal.modal-gift .gift-member .member-item>input {
    display: none;
}

.demoModal.modal-gift .gift-member .member-item .RadioLabel {
    border: 1px solid var(--ColorTypeD);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 1rem 2rem;
    font-size: 20px;
    font-weight: var(--Medium);
}

.demoModal.modal-gift .gift-member .member-item>input:checked+.RadioLabel {
    border-color: var(--ColorTypeA);
}

.demoModal.modal-gift .gift-member .member-item+.member-item {
    margin-top: 0.75rem;
}

.demoModal.modal-gift .gift-member .member-item .imgbox {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 3px;
}

.demoModal.modal-gift .gift-member .member-item .imgbox.kakao {
    background: #FEE500
}

.demoModal.modal-gift .gift-member .member-item .imgbox.naver {
    background: #00BF18;
}

.demoModal.modal-gift .gift-member .member-item .imgbox.apple {
    background: var(--ColorTypeB);
}

.demoModal.modal-gift .gift-member .member-item .imgbox .sns-icon {
    max-width: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.demoModal.modal-gift .btn-giftBtn {
    color: #fff;
    background-color: var(--ColorTypeB);
    max-width: 300px;
    margin: 0 auto 2.5rem;
    font-size: 20px;
    padding: 1rem 0;
    border-radius: 30px;
}

@media screen and (max-width: 599px) {

    .TGiftPage .GiftBox .infobox {
        padding: 1.5rem 1.25rem 2rem;
    }

    .demoModal.modal-gift .gift-member {
        max-width: 200px;
    }

    .demoModal.modal-gift .gift-member .member-item .RadioLabel {
        padding: 0.5rem 1rem;
        font-size: 15px;
    }

    .demoModal.modal-gift .gift-member .member-item .imgbox {
        width: 25px;
        height: 25px;
    }

    .demoModal.modal-gift .gift-member .member-item .imgbox .sns-icon {
        max-width: 12px;
    }

    .demoModal.modal-gift .btn-giftBtn {
        max-width: 200px;
        margin: 0 auto 1.5rem;
        font-size: 15px;
        padding: 0.75rem 0;
    }

}