.TWeekMenuPage {
  position: relative;
}

.TWeekMenuPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TWeekMenuPage .tab-content {
  padding-top: 0;
}

.TWeekMenuPage .WeekCateTab .nav-pills {
  background-color: #fff;
  border-bottom: 1px solid var(--ColorTypeD);
  padding-top: 2rem;
}

.TWeekMenuPage .WeekCateTab .nav-pills .nav-item {
  width: 20%;
}

.TWeekMenuPage .WeekCateBtn {
  font-weight: var(--Medium);
  font-size: 22px;
  color: var(--ColorTypeB);
  border-radius: 0;
  --bs-nav-link-padding-x: 0.5rem;
  position: relative;
  line-height: 1.15;
  width: 100%;
  padding-bottom: 0.75rem;
  border-bottom: 7px solid transparent;
}

.TWeekMenuPage .nav-pills .WeekCateBtn.active {
  color: var(--ColorTypeA);
  border-radius: 0;
  background-color: transparent;
  border-color: var(--ColorTypeA);
}

.TWeekMenuPage .WeekCateBtn span {
  position: relative;
}

/* .TWeekMenuPage .WeekCateBtn.active span::before {
    content: "";
    position: absolute;
    right: -8px;
    top: -8px;
    width: 6px;
    height: 6px;
    background: var(--ColorTypeA);
    border-radius: 50%;
} */

.TWeekMenuPage .WeekMenuBox {
  min-height: 60vh;
}

.TWeekMenuPage .WeekMenuBox .WeekMenu-Item {
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 2rem 2.5rem;
  border-radius: 0.75rem;
  min-height: auto;
  box-shadow: 1px 2px 1px 1px rgb(0 0 0 / 10%);
  margin-bottom: 1.5rem;
}

.TWeekMenuPage .WeekMenuBox .WeekMenu-Item .prd-infobox .prd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 30px;
  margin-bottom: 0;
  word-break: break-word;
}

.TWeekMenuPage .WeekMenuBox .WeekMenu-Item .prd-infobox .prd-desc {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 22px;
  margin-bottom: 0;
  word-break: break-word;
}

.TWeekMenuPage .WeekMenuBox .WeekMenu-Blank {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TWeekMenuPage .WeekMenuBox .WeekMenu-Blank .no_itemImg {
  max-width: 110px;
  display: block;
  margin: 0 auto 2rem;
}

.TWeekMenuPage .WeekMenuBox .WeekMenu-Blank .no_txt {
  font-size: 22px;
  text-align: center;
  color: var(--ColorTypeC);
  font-weight: var(--Medium);
}

/* 모바일 반응형 */

@media screen and (max-width: 1024px) {
  .TWeekMenuPage::before {
    border-left: 0px;
    border-right: 0px;
  }

  .TWeekMenuPage .WeekCateTab .nav-pills {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .TWeekMenuPage .WeekCateBtn {
    font-size: 15px;
    padding-bottom: 0.5rem;
    border-width: 5px;
  }

  .demo .TWeekMenuPage .WeekMenuBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TWeekMenuPage .WeekMenuBox .WeekMenu-Item {
    padding: 1.25rem 1.5rem;
    margin-bottom: 1.25rem;
  }

  .TWeekMenuPage .WeekMenuBox .WeekMenu-Item .prd-infobox .prd-tit {
    font-size: 22px;
  }

  .TWeekMenuPage .WeekMenuBox .WeekMenu-Item .prd-infobox .prd-desc {
    font-size: 15px;
  }

  .TWeekMenuPage .WeekMenuBox .WeekMenu-Blank .no_itemImg {
    max-width: 75px;
    margin: 0 auto 1rem;
  }

  .TWeekMenuPage .WeekMenuBox .WeekMenu-Blank .no_txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  .TWeekMenuPage .WeekCateBtn {
    font-size: 13px;
    border-width: 4px;
  }
}

/* Type B */

.TWeekMenuPage.typeB::before {
  background: #fff;
}

.TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  box-shadow: none;
}

.TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item .prd-infobox {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 2rem 0;
  position: relative;
}

.TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item .prd-infobox::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
  height: 100%;
  background-color: #f7f7f7;
}

.TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item .prd-infobox::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--ColorTypeE);
}

.TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item .prd-infobox .prd-tit {
  width: 30%;
  text-align: center;
  position: relative;
  line-height: 1.3;
  font-size: 20px;
}

.TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item .prd-infobox .prd-desc {
  width: 70%;
  padding: 0rem 2rem;
  position: relative;
}

@media screen and (max-width: 599px) {
  .TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item .prd-infobox {
    padding: 1.25rem 0;
  }

  .TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item .prd-infobox .prd-desc {
    padding: 0rem 1.5rem;
  }
}

@media screen and (max-width: 320px) {
  .TWeekMenuPage.typeB .WeekMenuBox .WeekMenu-Item .prd-infobox .prd-desc {
    padding: 0rem 1rem;
  }
}
