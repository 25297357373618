/* 23-01-09 추가 */

.TQRwalletPage {
  position: relative;
}

.TQRwalletPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TQRwalletPage .tab-content {
  padding-top: 0;
}

.TQRwalletPage .PayCateTab .nav-pills {
  background-color: #fff;
  border-bottom: 1px solid var(--ColorTypeE);
  padding-top: 2rem;
}

.TQRwalletPage .PayCateTab .nav-pills .nav-item {
  width: 50%;
}

.TQRwalletPage .PayCateBtn {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeD);
  border-radius: 0;
  --bs-nav-link-padding-x: 0.5rem;
  position: relative;
  line-height: 1.15;
  width: 100%;
  padding-bottom: 1.25rem;
}

.TQRwalletPage .nav-pills .PayCateBtn.active {
  color: var(--ColorTypeB);
  border-radius: 0;
  background-color: transparent;
  border-bottom: 2px solid var(--ColorTypeA);
}

.TQRwalletPage .PayCateBtn span {
  position: relative;
}

/* .TQRwalletPage .PayCateBtn.active span::before {
    content: "";
    position: absolute;
    right: -8px;
    top: -8px;
    width: 6px;
    height: 6px;
    background: var(--ColorTypeA);
    border-radius: 50%;
} */

.TQRwalletPage .PayListItem {
  margin-bottom: 1rem;
}

.TQRwalletPage .PayListItem .infobox {
  /* background: url(https://www.flexdaycdn.net/public/images/ticket/QR_ticket_bg.png) no-repeat center/100% 100%; */
  position: relative;
}

.TQRwalletPage .PayListItem .infobox::before {
  content: "";
  width: 1rem;
  height: 0.5rem;
  border-radius: 0 0 150px 150px;
  position: absolute;
  left: calc(77% - 0.5rem);
  top: -1px;
  background-color: #f7f7f7;
  box-shadow: inset 0px -3px 3px rgb(0 0 0 / 10%);
  z-index: 5;
}

.TQRwalletPage .PayListItem .infobox::after {
  content: "";
  width: 1rem;
  height: 0.75rem;
  border-radius: 150px 150px 0 0;
  position: absolute;
  left: calc(77% - 0.5rem);
  bottom: -5px;
  background-color: #f7f7f7;
  box-shadow: inset 0px 3px 3px rgb(0 0 0 / 10%);
  z-index: 5;
}

.TQRwalletPage .PayListItem .infobox .InfoboxTxt {
  padding: 2rem 0 0;
  width: 77%;
  background-color: #fff;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
  border-top-left-radius: 2rem;
}

.TQRwalletPage .PayListItem .infobox .InfoboxTxt .txtbox {
  padding: 0 2rem;
}

.TQRwalletPage .PayListItem .infobox .store-name {
  font-weight: var(--Regular);
  font-size: 20px;
  color: #231815;
  word-break: break-word;
  margin-bottom: 0;
}

.TQRwalletPage .PayListItem .infobox .prd-name {
  font-weight: var(--Semibold);
  font-size: 30px;
  color: var(--ColorTypeB);
  word-break: break-word;
}

.TQRwalletPage .PayListItem .infobox .giftbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  border-top: 1px solid #f5f5f5;
  margin-top: 2.5rem;
  gap: 0 0.5rem;
}

.TQRwalletPage .PayListItem .infobox .ungiftbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  /* border-top: 1px solid #f5f5f5; */
  margin-top: 2.5rem;
  gap: 0 0.5rem;
}

.TQRwalletPage .PayListItem .infobox .giftbox .gift {
  width: 24px;
}

.TQRwalletPage .PayListItem .infobox .giftbox span {
  font-size: 20px;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
}

.TQRwalletPage .PayListItem .infobox .giftbox .arrow {
  width: 12px;
  filter: brightness(0);
  margin-left: 0.75rem;
}

.TQRwalletPage .PayListItem .infobox .QRBtn {
  width: 23%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
  text-align: center;
  background-color: var(--ColorTypeA);
  border-bottom-right-radius: 2rem;
}
.TQRwalletPage .PayListItem .infobox .QRBtn .btn {
  color: #fff;
  font-size: 20px;
  line-height: 1.2;
}

.TQRwalletPage .PayListItem .infobox .QRBtn .btn img {
  width: 40px;
  display: block;
  margin: 0 auto 0.5rem;
}

.TQRwalletPage .PayListItem.used .infobox .InfoboxTxt {
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
}

.TQRwalletPage .PayListItem.used .infobox .InfoboxTxt::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  top: 0;
}

.TQRwalletPage .PayListItem.used .infobox .prd-date {
  font-weight: var(--Regular);
  font-size: 20px;
  color: #231815;
  margin-bottom: 0.5rem;
}

.TQRwalletPage .PayListItem.used .infobox .prd-stamp {
  position: absolute;
  right: 5%;
  top: -8px;
  width: 35%;
  max-width: 115px;
}

.TQRwalletPage .PayListItem.survey-done .infobox .QRBtn {
  background-color: #999999;
  pointer-events: none;
}

.TQRwalletPage::before {
  background: #f7f7f7;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.TQRwalletPage .PayCateTab .nav-pills {
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

/* 모바일 반응형 */

@media screen and (max-width: 1024px) {
  .TQRwalletPage::before {
    border-left: 0px;
    border-right: 0px;
  }

  .TQRwalletPage .PayCateTab .nav-pills {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .TQRwalletPage .PayCateBtn {
    font-size: 17px;
    padding-bottom: 1rem;
  }

  .TQRwalletPage .PayListItem .infobox .InfoboxTxt {
    padding: 1.5rem 0 0;
    border-top-left-radius: 1.25rem;
  }

  .TQRwalletPage .PayListItem.used .infobox .InfoboxTxt {
    padding: 1.5rem 0;
  }

  .TQRwalletPage .PayListItem .infobox .InfoboxTxt .txtbox {
    padding: 0 1.5rem;
  }

  .TQRwalletPage .PayListItem .infobox .store-name {
    font-size: 15px;
  }

  .TQRwalletPage .PayListItem .infobox .prd-name {
    font-size: 22px;
  }

  .TQRwalletPage .PayListItem.used .infobox .prd-date {
    font-size: 15px;
  }

  .TQRwalletPage .PayListItem .infobox .QRBtn {
    border-bottom-right-radius: 1.25rem;
  }

  .TQRwalletPage .PayListItem .infobox .QRBtn .btn {
    font-size: 14px;
  }

  .TQRwalletPage .PayListItem .infobox .QRBtn .btn img {
    width: 25px;
  }

  .TQRwalletPage .PayListItem .infobox .giftbox {
    padding: 0.75rem 1.5rem;
    margin-top: 1.5rem;
    gap: 0 0.25rem;
  }

  .TQRwalletPage .PayListItem .infobox .giftbox .gift {
    width: 18px;
  }

  .TQRwalletPage .PayListItem .infobox .giftbox span {
    font-size: 15px;
  }

  .TQRwalletPage .PayListItem .infobox .giftbox .arrow {
    width: 7px;
    margin-left: 0.4rem;
  }
}

@media screen and (max-width: 320px) {
  .TQRwalletPage .PayListItem .infobox .prd-name {
    font-size: 20px;
  }

  .TQRwalletPage .PayListItem .infobox .store-name {
    font-size: 13px;
  }

  .TQRwalletPage .PayListItem.used .infobox .prd-date {
    font-size: 13px;
  }
}

/* 23-01-09 추가 */

.TQRwalletPage {
  position: relative;
}

.TQRwalletPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TQRwalletPage .tab-content {
  padding-top: 0;
}

.TQRwalletPage .PayCateTab .nav-pills {
  background-color: #fff;
  border-bottom: 1px solid var(--ColorTypeE);
  padding-top: 2rem;
}

.TQRwalletPage .PayCateTab .nav-pills .nav-item {
  width: 50%;
}

.TQRwalletPage .PayCateBtn {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeD);
  border-radius: 0;
  --bs-nav-link-padding-x: 0.5rem;
  position: relative;
  line-height: 1.15;
  width: 100%;
  padding-bottom: 1.25rem;
}

.TQRwalletPage .nav-pills .PayCateBtn.active {
  color: var(--ColorTypeB);
  border-radius: 0;
  background-color: transparent;
  border-bottom: 2px solid var(--ColorTypeA);
}

.TQRwalletPage .PayCateBtn span {
  position: relative;
}

/* .TQRwalletPage .PayCateBtn.active span::before {
    content: "";
    position: absolute;
    right: -8px;
    top: -8px;
    width: 6px;
    height: 6px;
    background: var(--ColorTypeA);
    border-radius: 50%;
} */

.TQRwalletPage .PayListItem {
  margin-bottom: 1rem;
}

.TQRwalletPage .PayListItem .infobox {
  /* background: url(https://www.flexdaycdn.net/public/images/ticket/QR_ticket_bg.png) no-repeat center/100% 100%; */
  position: relative;
}

.TQRwalletPage .PayListItem .infobox::before {
  content: "";
  width: 1rem;
  height: 0.5rem;
  border-radius: 0 0 150px 150px;
  position: absolute;
  left: calc(77% - 0.5rem);
  top: -1px;
  background-color: #f7f7f7;
  box-shadow: inset 0px -3px 3px rgb(0 0 0 / 10%);
  z-index: 5;
}

.TQRwalletPage .PayListItem .infobox::after {
  content: "";
  width: 1rem;
  height: 0.75rem;
  border-radius: 150px 150px 0 0;
  position: absolute;
  left: calc(77% - 0.5rem);
  bottom: -5px;
  background-color: #f7f7f7;
  box-shadow: inset 0px 3px 3px rgb(0 0 0 / 10%);
  z-index: 5;
}

.TQRwalletPage .PayListItem .infobox .InfoboxTxt {
  padding: 2rem 0 0;
  width: 77%;
  background-color: #fff;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
  border-top-left-radius: 2rem;
}

.TQRwalletPage .PayListItem .infobox .InfoboxTxt .txtbox {
  padding: 0 2rem;
}

.TQRwalletPage .PayListItem .infobox .store-name {
  font-weight: var(--Regular);
  font-size: 20px;
  color: #231815;
  word-break: break-word;
  margin-bottom: 0;
}

.TQRwalletPage .PayListItem .infobox .prd-name {
  font-weight: var(--Semibold);
  font-size: 30px;
  color: var(--ColorTypeB);
  word-break: break-word;
}

.TQRwalletPage .PayListItem .infobox .giftbox,
.TQRwalletPage .PayListItem .infobox .ungiftbox {
  align-items: center;
  display: flex;
  gap: 0 0.5rem;
  justify-content: center;
  margin-top: 2.5rem;
  padding: 1rem 2rem;
}

.TQRwalletPage .PayListItem .infobox .giftbox .gift {
  width: 24px;
}

.TQRwalletPage .PayListItem .infobox .giftbox span {
  font-size: 20px;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
}

.TQRwalletPage .PayListItem .infobox .giftbox .arrow {
  width: 12px;
  filter: brightness(0);
  margin-left: 0.75rem;
}

.TQRwalletPage .PayListItem .infobox .QRBtn {
  width: 23%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
  text-align: center;
  background-color: var(--ColorTypeA);
  border-bottom-right-radius: 2rem;
}

.TQRwalletPage .PayListItem .infobox .QRBtn .btn {
  color: #fff;
  font-size: 20px;
  line-height: 1.2;
}

.TQRwalletPage .PayListItem .infobox .QRBtn .btn img {
  width: 40px;
  display: block;
  margin: 0 auto 0.5rem;
}

.TQRwalletPage .PayListItem.used .infobox .InfoboxTxt {
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
}

.TQRwalletPage .PayListItem.used .infobox .InfoboxTxt::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  top: 0;
}

.TQRwalletPage .PayListItem.used .infobox .prd-date {
  font-weight: var(--Regular);
  font-size: 20px;
  color: #231815;
  margin-bottom: 0.5rem;
}

.TQRwalletPage .PayListItem.used .infobox .prd-stamp {
  position: absolute;
  right: 5%;
  top: -8px;
  width: 35%;
  max-width: 115px;
}

.TQRwalletPage .PayListItem.survey-done .infobox .QRBtn {
  background-color: #999999;
  pointer-events: none;
}

.TQRwalletPage::before {
  background: #f7f7f7;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.TQRwalletPage .PayCateTab .nav-pills {
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

/* 모바일 반응형 */

@media screen and (max-width: 1024px) {
  .TQRwalletPage::before {
    border-left: 0px;
    border-right: 0px;
  }

  .TQRwalletPage .PayCateTab .nav-pills {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .TQRwalletPage .PayCateBtn {
    font-size: 17px;
    padding-bottom: 1rem;
  }

  .TQRwalletPage .PayListItem .infobox .InfoboxTxt {
    padding: 1.5rem 0 0;
    border-top-left-radius: 1.25rem;
  }

  .TQRwalletPage .PayListItem.used .infobox .InfoboxTxt {
    padding: 1.5rem 0;
  }

  .TQRwalletPage .PayListItem .infobox .InfoboxTxt .txtbox {
    padding: 0 1.5rem;
  }

  .TQRwalletPage .PayListItem .infobox .store-name {
    font-size: 15px;
  }

  .TQRwalletPage .PayListItem .infobox .prd-name {
    font-size: 22px;
  }

  .TQRwalletPage .PayListItem.used .infobox .prd-date {
    font-size: 15px;
  }

  .TQRwalletPage .PayListItem .infobox .QRBtn {
    border-bottom-right-radius: 1.25rem;
  }

  .TQRwalletPage .PayListItem .infobox .QRBtn .btn {
    font-size: 14px;
  }

  .TQRwalletPage .PayListItem .infobox .QRBtn .btn img {
    width: 25px;
  }

  .TQRwalletPage .PayListItem .infobox .giftbox {
    padding: 0.75rem 1.5rem;
    margin-top: 1.5rem;
    gap: 0 0.25rem;
  }

  .TQRwalletPage .PayListItem .infobox .giftbox .gift {
    width: 18px;
  }

  .TQRwalletPage .PayListItem .infobox .giftbox span {
    font-size: 15px;
  }

  .TQRwalletPage .PayListItem .infobox .giftbox .arrow {
    width: 7px;
    margin-left: 0.4rem;
  }
}

@media screen and (max-width: 320px) {
  .TQRwalletPage .PayListItem .infobox .prd-name {
    font-size: 20px;
  }

  .TQRwalletPage .PayListItem .infobox .store-name {
    font-size: 13px;
  }

  .TQRwalletPage .PayListItem.used .infobox .prd-date {
    font-size: 13px;
  }
}

/* ver2 */

.TQRwalletPage::before {
  background-color: #fff;
}

.TQRwalletPage .PayListItem .infobox .InfoboxTxt {
  border: 1px solid #f7f7f7;
}

.TQRwalletPage .PayListItem.unuse .infobox .InfoboxTxt {
  position: relative;
  overflow: hidden;
}

.TQRwalletPage .PayListItem.unuse .infobox .prd-stamp {
  position: absolute;
  right: 5%;
  top: -8px;
  width: 35%;
  max-width: 115px;
}

.TQRwalletPage .PayListItem .infobox .giftbox {
  border-top: 1px solid #f7f7f7;
}

.TQRwalletPage .PayListItem .infobox .ungiftbox {
  padding: 0;
  margin-top: 3rem;
}

.TQRwalletPage .PayListItem .infobox .QRBtn {
  background-color: var(--ColorTypeB);
}

.TQRwalletPage .PayPrdList.gift {
  border-top: 15px solid #f7f7f7;
  padding-top: 3rem;
  margin-top: 4rem;
  position: relative;
}

.TQRwalletPage .PayPrdList.gift::before {
  content: "";
  position: absolute;
  left: 0;
  top: -15px;
  background-color: #ededed;
  width: 100%;
  height: 5px;
}

.TQRwalletPage .PayPrdList.gift .giftTxt {
  font-size: 20px;
  color: var(--ColorTypeB);
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  margin-bottom: 1.5rem;
}

.TQRwalletPage .PayPrdList.gift .giftTxt > .img {
  max-width: 26px;
}

.TQRwalletPage .PayPrdList.gift .PayListItem .infobox .QRBtn {
  background-color: var(--ColorTypeA);
}

@media screen and (max-width: 599px) {
  .TQRwalletPage .PayListItem .infobox::before {
    width: 0.6rem;
    height: 0.4rem;
    left: calc(77% - 0.35rem);
  }

  .TQRwalletPage .PayListItem .infobox .ungiftbox {
    margin-top: 2rem;
  }

  .TQRwalletPage .PayPrdList.gift {
    padding-top: 2rem;
    margin-top: 3rem;
  }

  .TQRwalletPage .PayPrdList.gift .giftTxt {
    font-size: 14px;
    gap: 0 0.25rem;
    margin-bottom: 1rem;
  }

  .TQRwalletPage .PayPrdList.gift .giftTxt > .img {
    max-width: 18px;
  }
}

/* 23.05.08 수정 */
.TQRwalletPage .PayPrdList.gift .PayListItem {
  margin-bottom: 3rem;
}
.TQRwalletPage .PayPrdList.gift .PayListItem:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 599px) {
  .TQRwalletPage .PayPrdList.gift .PayListItem {
    margin-bottom: 2rem;
  }
}
