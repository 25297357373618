.TLogininfoPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TLogininfoPage .LogininfoItem {
  border-bottom: 10px solid #f8f8f8;
  position: relative;
}

.TLogininfoPage .LogininfoItem::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ededed;
}

.TLogininfoPage .LogininfoBox .InputboxWrap {
  margin-bottom: 5.5rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap:last-child {
  margin-bottom: 0;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .tit {
  font-size: 22px;
  color: var(--ColorTypeB);
  font-weight: var(--Semibold);
  word-break: keep-all;
  margin-bottom: 3rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .info-txt {
  font-size: 18px;
  color: var(--ColorTypeC);
  font-weight: var(--Medium);
  margin-bottom: 1.5rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .input-tit {
  font-size: 18px;
  color: var(--ColorTypeC);
  font-weight: var(--Semibold);
  margin-bottom: 0.5rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .form-control {
  padding: 1.5rem;
  height: 60px;
  font-size: 17px;
  border-radius: 0.5rem;
  background-color: #f7f7f7f7;
  border: 2px solid var(--ColorTypeD);
  color: #959595;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {
  .demo .TLogininfoPage .LogininfoBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap {
    margin-bottom: 4rem;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .tit {
    font-size: 18px;
    margin-bottom: 2rem;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .info-txt {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .input-tit {
    font-size: 15px;
    margin-bottom: 0.25rem;
  }

  .TLogininfoPage
    .LogininfoBox
    .InputboxWrap
    .Logininfo-Inputbox
    .form-control {
    padding: 0.55rem 1.25rem;
    height: 50px;
    font-size: 14px;
  }
}

.TLogininfoPage .LogininfoItem {
  border-bottom: 10px solid #f8f8f8;
  position: relative;
}

.TLogininfoPage .LogininfoItem::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ededed;
}

.TLogininfoPage .LogininfoBox .InputboxWrap {
  margin-bottom: 5.5rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap:last-child {
  margin-bottom: 0;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .tit {
  font-size: 22px;
  color: var(--ColorTypeB);
  font-weight: var(--Semibold);
  word-break: keep-all;
  margin-bottom: 3rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .info-txt {
  font-size: 18px;
  color: var(--ColorTypeC);
  font-weight: var(--Medium);
  margin-bottom: 1.5rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .input-tit {
  font-size: 18px;
  color: var(--ColorTypeC);
  font-weight: var(--Semibold);
  margin-bottom: 0.5rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .form-control {
  padding: 1.5rem;
  height: 60px;
  font-size: 17px;
  border-radius: 0.5rem;
  background-color: #f7f7f7f7;
  border: 2px solid var(--ColorTypeD);
  color: #959595;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm {
  margin-top: 4rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm .txt {
  font-size: 18px;
  color: var(--ColorTypeC);
  margin-bottom: 1.5rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm .btn {
  height: 70px;
  font-size: 22px;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {
  .demo .TLogininfoPage .LogininfoBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap {
    margin-bottom: 4rem;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .tit {
    font-size: 18px;
    margin-bottom: 2rem;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .info-txt {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .input-tit {
    font-size: 15px;
    margin-bottom: 0.25rem;
  }

  .TLogininfoPage
    .LogininfoBox
    .InputboxWrap
    .Logininfo-Inputbox
    .form-control {
    padding: 0.55rem 1.25rem;
    height: 50px;
    font-size: 14px;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm {
    margin-top: 3rem;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm .txt {
    font-size: 14px;
    margin-bottom: 1rem;
    word-break: keep-all;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm .btn {
    height: 55px;
    font-size: 16px;
  }
}

/* ver2 */

.TLogininfoPage .tab-content {
  padding-top: 0;
}

.TLogininfoPage .LoginInfoTab .nav-pills {
  background-color: #fff;
  border-bottom: 1px solid var(--ColorTypeE);
  padding-top: 2rem;
}

.TLogininfoPage .LoginInfoTab .nav-pills .nav-item {
  width: 50%;
}

.TLogininfoPage .LoginInfoBtn {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeD);
  border-radius: 0;
  --bs-nav-link-padding-x: 0.5rem;
  position: relative;
  line-height: 1.15;
  width: 100%;
  padding-bottom: 1.25rem;
}

.TLogininfoPage .nav-pills .LoginInfoBtn.active {
  color: var(--ColorTypeB);
  border-radius: 0;
  background-color: transparent;
  border-bottom: 2px solid var(--ColorTypeA);
}

.TLogininfoPage .LoginInfoBtn span {
  position: relative;
}

.TLogininfoPage .LogininfoTxt {
  position: relative;
  background-color: #f7f7f7;
  text-align: center;
  padding: 4.5rem 3rem 4rem;
}

.TLogininfoPage .LogininfoTxt::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background-color: #ededed;
  width: 100%;
  height: 5px;
}

.TLogininfoPage .LogininfoTxt .icon {
  max-width: 30px;
  margin-bottom: 1rem;
}

.TLogininfoPage .LogininfoTxt .txt {
  font-size: 20px;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
}

.TLogininfoPage .LogininfoBox .InputboxWrap .info-txt {
  font-size: 24px;
  color: var(--ColorTypeB);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.5rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .info-txt .imgbox {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 2px;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .info-txt .imgbox.kakao {
  background: #fee500;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .info-txt .imgbox.naver {
  background: #00bf18;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .info-txt .imgbox.apple {
  background: var(--ColorTypeB);
}

.TLogininfoPage .LogininfoBox .InputboxWrap .info-txt .imgbox .sns-icon {
  max-width: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .input-tit {
  font-size: 20px;
  color: var(--ColorTypeB);
  font-weight: var(--Regular);
}

.TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .form-control {
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid var(--ColorTypeB);
  color: var(--ColorTypeC);
}

.TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm {
  margin-top: 6rem;
}

.TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm .txt {
  margin-bottom: 0;
  margin-top: 1.25rem;
}

.TLogininfoPage .LoginMarketing {
  margin-top: 5rem;
  padding: 0 3rem;
}

.TLogininfoPage .LoginMarketing .form-check-input.CheckInput2 {
  background-size: 20px auto;
  margin-right: 12px;
  width: 32px;
  height: 32px;
}

.TLogininfoPage .LoginMarketing .form-check-input.CheckInput3 {
  margin-right: 12px;
  width: 32px;
  height: 32px;
}

.TLogininfoPage .LoginMarketing .CheckLabel {
  font-size: 20px;
}

.TLogininfoPage .LoginMarketing .SignUp-Infobox {
  padding-left: 44px;
  margin-top: 1rem;
}

.TLogininfoPage .LoginMarketing .SignUp-checkbox-desc {
  color: #999999;
  font-size: 18px;
  margin-bottom: 1.5rem;
}

.TLogininfoPage
  .LoginMarketing
  .SignUp-Infobox
  .form-check-group
  .form-check
  + .form-check {
  margin-top: 1rem;
}

.TLogininfoPage .LoginMarketing .LogininfoConfirm .btn {
  height: 70px;
  font-size: 22px;
  margin-top: 15rem;
}

@media screen and (max-width: 1024px) {
  .TLogininfoPage .LoginInfoTab .nav-pills {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .TLogininfoPage .LoginInfoBtn {
    font-size: 17px;
    padding-bottom: 1rem;
  }

  .TLogininfoPage .LogininfoTxt {
    padding: 3.5rem 0.5rem 3.2rem;
  }

  .TLogininfoPage .LogininfoTxt .icon {
    max-width: 20px;
    margin-bottom: 0.75rem;
  }

  .TLogininfoPage .LogininfoTxt .txt {
    font-size: 14px;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .info-txt {
    font-size: 18px;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .info-txt .imgbox {
    width: 20px;
    height: 20px;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .info-txt .imgbox .sns-icon {
    max-width: 10px;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .Logininfo-Inputbox .input-tit {
    font-size: 15px;
  }

  .TLogininfoPage
    .LogininfoBox
    .InputboxWrap
    .Logininfo-Inputbox
    .form-control {
    font-size: 15px;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm {
    margin-top: 5rem;
  }

  .TLogininfoPage .LogininfoBox .InputboxWrap .LogininfoConfirm .txt {
    margin-top: 1rem;
  }

  .TLogininfoPage .LoginMarketing {
    margin-top: 3.5rem;
    padding: 0 1.5rem;
  }

  .TLogininfoPage .LoginMarketing .form-check-input.CheckInput2 {
    background-size: 15px auto;
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  .TLogininfoPage .LoginMarketing .CheckLabel {
    font-size: 14px;
  }

  .TLogininfoPage .LoginMarketing .SignUp-Infobox {
    padding-left: 30px;
    margin-top: 0.5rem;
  }

  .TLogininfoPage .LoginMarketing .SignUp-checkbox-desc {
    font-size: 13px;
    margin-bottom: 1rem;
  }

  .TLogininfoPage .LoginMarketing .form-check-input.CheckInput3 {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  .TLogininfoPage .LoginMarketing .LogininfoConfirm .btn {
    height: 55px;
    font-size: 16px;
    margin-top: 12rem;
  }
}

@media screen and (max-width: 320px) {
  .TLogininfoPage .LoginMarketing .LogininfoConfirm .btn {
    margin-top: 6rem;
  }
}
