.TGiftListPage {
  position: relative;
}

.TGiftListPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TGiftListPage .tab-content {
  padding-top: 0;
}

.TGiftListPage .PayCateTab .nav-pills {
  background-color: #fff;
  border-bottom: 1px solid var(--ColorTypeE);
  padding-top: 2rem;
}

.TGiftListPage .PayCateTab .nav-pills .nav-item {
  width: 50%;
}

.TGiftListPage .PayCateBtn {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeD);
  border-radius: 0;
  --bs-nav-link-padding-x: 0.5rem;
  position: relative;
  line-height: 1.15;
  width: 100%;
  padding-bottom: 1.25rem;
}

.TGiftListPage .nav-pills .PayCateBtn.active {
  color: var(--ColorTypeB);
  border-radius: 0;
  background-color: transparent;
  border-bottom: 2px solid var(--ColorTypeA);
}

.TGiftListPage .PayCateBtn span {
  position: relative;
}

/* .TGiftListPage .PayCateBtn.active span::before {
    content: "";
    position: absolute;
    right: -8px;
    top: -8px;
    width: 6px;
    height: 6px;
    background: var(--ColorTypeA);
    border-radius: 50%;
} */

.TGiftListPage::before {
  background: #f7f7f7;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.TGiftListPage .PayCateTab .nav-pills {
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.TGiftList-modal .modal-dialog.modal-sm.modal-prdfilter {
  --bs-modal-width: 100%;
  width: 100%;
  transform: translateY(50px);
}

.TGiftList-modal.modal.show .modal-dialog.modal-prdfilter {
  transform: none;
}

.TGiftList-modal .modal-prdfilter {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  height: auto;
  display: block;
}

.TGiftList-modal .modal-prdfilter .modal-content {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 0;
  border-radius: 0;
  background: #fff;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  overflow: hidden;
  will-change: transform;
}

.TGiftList-modal .modal-prdfilter .modal-header {
  padding: 2rem 3rem 1.5rem 4.5rem;
}

.TGiftList-modal .modal-prdfilter .modal-header .modal-title {
  font-size: 30px;
}

.TGiftList-modal .modal-prdfilter .modal-header .btn {
  max-width: 25px;
}

.TGiftList-modal .modal-prdfilter .modal-body {
  padding: 0 0 3rem;
}

.TGiftList-modal .modal-prdfilter .modal-bar .bar {
  width: 15%;
  height: 0.45rem;
  background-color: #cccccc;
  border-radius: 30px;
  margin: auto;
}

.TGiftList-modal .modal-prdfilter .form-sort .form-radio-input {
  display: none;
}

.TGiftList-modal
  .modal-prdfilter
  .form-sort
  .form-radio-input
  + .form-radio-label {
  position: relative;
  display: block;
  margin-bottom: 0;
  padding: 1rem 3rem 1rem 4.5rem;
  font-size: 25px;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
}

.TGiftList-modal
  .modal-prdfilter
  .form-sort
  .form-radio-input:checked
  + .form-radio-label {
  color: var(--ColorTypeA);
}

.TGiftList-modal .modal-prdfilter .form-sort > input:checked + label::before {
  background-color: var(--ColorTypeA);
}

.TGiftList-modal .modal-prdfilter .form-sort > label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 8px;
  height: 100%;
}

.TGiftListPage .GiftFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  background-color: #fff;
}

.TGiftListPage .GiftFilter .info-txt {
  font-size: 22px;
  color: var(--ColorTypeC);
  font-weight: var(--Regular);
}

.TGiftListPage .GiftFilter .sort {
  display: inline-flex;
  align-items: center;
  gap: 0 0.6rem;
  cursor: pointer;
}

.TGiftListPage .GiftFilter .sort .filter-txt {
  font-size: 24px;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
}

.TGiftListPage .GiftFilter .sort .arrow {
  max-width: 18px;
}

.TGiftListPage .GiftList {
  padding-top: 3rem;
}

.TGiftListPage .GiftItem {
  margin-bottom: 3rem;
}

.TGiftListPage .GiftItem .date {
  font-size: 22px;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  margin-bottom: 1rem;
}

.TGiftListPage .GiftItem .date .txt {
  font-weight: var(--Regular);
  position: relative;
}

.TGiftListPage .GiftItem .date .txt::after {
  content: "ㅣ";
  display: inline-block;
  margin-right: 0.7rem;
  margin-left: 0.7rem;
}

.TGiftListPage .GiftItem .box {
  position: relative;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 1px 2px 1px 1px rgb(0 0 0 / 10%);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.TGiftListPage .GiftItem .box .infobox {
  flex: 1;
  padding: 2rem 2rem 2.75rem;
}

.TGiftListPage .GiftItem .box .to {
  font-size: 22px;
  color: var(--ColorTypeB);
  font-weight: var(--Regular);
  margin-bottom: 1rem;
}

.TGiftListPage .GiftItem .box .to .txt {
  font-weight: var(--Medium);
  margin-right: 0.5rem;
}

.TGiftListPage .GiftItem .box .prd-name {
  font-weight: var(--Semibold);
  font-size: 30px;
  color: var(--ColorTypeB);
  word-break: break-word;
}

.TGiftListPage .GiftItem .box .state {
  font-size: 22px;
  color: var(--ColorTypeC);
  text-align: right;
  padding: 0 2rem 2rem;
  padding-left: 0;
}

.TGiftListPage .GiftItem.used .box .state {
  color: var(--ColorTypeA);
}

.TGiftListPage .GiftList.receive .GiftItem .box {
  align-items: normal;
  overflow: hidden;
}

.TGiftListPage .GiftList.receive .GiftItem .box .GiftBtn {
  background-color: var(--ColorTypeB);
  padding: 2rem 1.5rem;
  display: flex;
  align-items: center;
}

.TGiftListPage .GiftList.receive .GiftItem .box .GiftBtn .btn {
  font-size: 20px;
  width: 100%;
  height: 100%;
}

/* 모바일 반응형 */

@media screen and (max-width: 1024px) {
  .TGiftListPage::before {
    border-left: 0px;
    border-right: 0px;
  }

  .TGiftListPage .PayCateTab .nav-pills {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .TGiftListPage .GiftFilter.px-4-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .TGiftListPage .GiftList.px-4-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .TGiftListPage .PayCateBtn {
    font-size: 17px;
    padding-bottom: 1rem;
  }

  .TGiftList-modal .modal-prdfilter .modal-bar .bar {
    width: 15%;
    height: 0.28rem;
  }

  .TGiftList-modal .modal-prdfilter .modal-header {
    padding: 1rem 2rem 0.75rem 3rem;
  }

  .TGiftList-modal .modal-prdfilter .modal-header .modal-title {
    font-size: 18px;
  }

  .TGiftList-modal .modal-prdfilter .modal-header .btn {
    max-width: 16px;
  }

  .TGiftList-modal .modal-prdfilter .modal-body {
    padding: 0 0 2rem;
  }

  .TGiftList-modal
    .modal-prdfilter
    .form-sort
    .form-radio-input
    + .form-radio-label {
    padding: 0.7rem 2rem 0.7rem 3rem;
    font-size: 16px;
  }

  .TGiftList-modal .modal-prdfilter .form-sort > label::before {
    width: 5px;
  }

  .TGiftListPage .GiftFilter {
    padding: 1rem 0;
  }

  .TGiftListPage .GiftFilter .info-txt {
    font-size: 14px;
  }

  .TGiftListPage .GiftFilter .sort {
    gap: 0 0.35rem;
  }

  .TGiftListPage .GiftFilter .sort .filter-txt {
    font-size: 16px;
  }

  .TGiftListPage .GiftFilter .sort .arrow {
    max-width: 12px;
  }

  .TGiftListPage .GiftList {
    padding-top: 2rem;
  }

  .TGiftListPage .GiftItem .date {
    font-size: 15px;
    margin-bottom: 0.75rem;
  }

  .TGiftListPage .GiftItem .date .txt::after {
    margin-right: 0.4rem;
    margin-left: 0.4rem;
  }

  .TGiftListPage .GiftItem .box .infobox {
    padding: 1.25rem 1.25rem 1.5rem;
  }

  .TGiftListPage .GiftItem .box .to {
    font-size: 15px;
    margin-bottom: 0.5rem;
  }

  .TGiftListPage .GiftItem .box .to .txt {
    margin-right: 0.25rem;
  }

  .TGiftListPage .GiftItem .box .prd-name {
    font-size: 22px;
  }

  .TGiftListPage .GiftItem .box .state {
    font-size: 15px;
    padding: 0 1.25rem 1.25rem;
    padding-left: 0;
  }

  .TGiftListPage .GiftList.receive .GiftItem .box .GiftBtn {
    padding: 1.25rem 1rem;
  }

  .TGiftListPage .GiftList.receive .GiftItem .box .GiftBtn .btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .TGiftListPage .GiftFilter.px-4-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .TGiftListPage .GiftFilter .info-txt {
    font-size: 13px;
  }

  .TGiftListPage .GiftFilter .sort .filter-txt {
    font-size: 15px;
  }

  .TGiftListPage .GiftFilter .sort .arrow {
    max-width: 10px;
  }

  .TGiftListPage .GiftItem .box .infobox {
    padding: 1rem 1rem 1.25rem;
  }

  .TGiftListPage .GiftItem .box .to {
    font-size: 14px;
  }

  .TGiftListPage .GiftItem .box .prd-name {
    font-size: 20px;
  }

  .TGiftListPage .GiftItem .box .state {
    font-size: 14px;
    padding: 0 1rem 1rem;
    padding-left: 0;
  }

  .TGiftListPage .GiftList.receive .GiftItem .box .GiftBtn {
    padding: 1rem 0.75rem;
  }
}

/* ver3 */

.TGiftListPage .GiftItem {
  margin-bottom: 2rem;
}

.TGiftListPage .GiftItem .box {
  padding: 2.25rem 2rem;
  border-radius: 1.45rem;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.06);
}

.TGiftListPage .receive .GiftItem .box {
  display: block;
}

.TGiftListPage .GiftItem .box .infobox {
  padding: 0;
}

.TGiftListPage .GiftItem .box .state {
  padding: 0;
}

.TGiftListPage .GiftItem .infobox .store-name {
  font-weight: var(--Regular);
  font-size: 22px;
  color: #231815;
  word-break: break-word;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.TGiftListPage .GiftItem .box .btnbox {
  margin-top: 1.5rem;
}

.TGiftListPage .GiftItem .box .btnbox .btn-default {
  border-radius: 6px;
  font-size: 22px;
  height: 65px;
}

@media screen and (max-width: 599px) {
  .TGiftListPage .GiftItem .box {
    padding: 1.5rem;
  }

  .TGiftListPage .GiftItem .infobox .store-name {
    font-size: 15px;
    margin-top: 0.25rem;
  }

  .TGiftListPage .GiftItem .box .btnbox {
    margin-top: 1.25rem;
  }

  .TGiftListPage .GiftItem .box .btnbox .btn-default {
    font-size: 17px;
    height: 50px;
  }
}

@media screen and (max-width: 599px) {
  .TGiftListPage .GiftItem .box {
    padding: 1.25rem;
  }
}
